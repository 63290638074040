.tb-popup-menu-vertical-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 313.2px;
}
.tb-popup-menu {
  position: relative;
}
.tb-popup-menu:focus {
  outline: 0;
}
.tb-popup-menu.tb-popup-menu-inline {
  display: inline-block;
}
.tb-popup-menu.tb-popup-menu-inline >div {
  display: inline-block;
}
.tb-popup-menu-button {
  position: relative;
  height: 21px;
  line-height: 21px;
  border: 1px solid #b2b2b2;
  padding-left: 6px;
  background-color: #fff;
  cursor: pointer;
}
.tb-popup-menu-button >.tb-text {
  color: rgba(0,0,0,0.8);
  padding-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-popup-menu-button >.tb-line {
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  left: auto;
  width: 1px;
  background-color: #b2b2b2;
}
.tb-popup-menu-button >.tb-arrow {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 1px;
  left: auto;
  width: 19px;
  height: 19px;
  background: url(img/dropdown_arrow_normal.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-button:hover {
  border: 1px solid #666;
}
.tb-popup-menu-button:hover >.tb-text {
  color: #333;
}
.tb-popup-menu-button:hover >.tb-line {
  background-color: #666;
}
.tb-popup-menu-button:hover >.tb-arrow {
  background: url(img/dropdown_arrow_pressed.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-button.tb-selected,
.tb-popup-menu-button.tb-selected:hover {
  border: 1px solid #b2b2b2;
}
.tb-popup-menu-button.tb-selected >.tb-text,
.tb-popup-menu-button.tb-selected:hover >.tb-text {
  color: rgba(0,0,0,0.8);
}
.tb-popup-menu-button.tb-selected >.tb-line,
.tb-popup-menu-button.tb-selected:hover >.tb-line {
  background-color: #b2b2b2;
}
.tb-popup-menu-button.tb-selected >.tb-arrow,
.tb-popup-menu-button.tb-selected:hover >.tb-arrow {
  background: url(img/dropdown_arrow_normal.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-button.tb-disabled,
.tb-popup-menu-button.tb-disabled:hover {
  cursor: default;
  border: 1px solid #e1e1e1;
}
.tb-popup-menu-button.tb-disabled >.tb-text,
.tb-popup-menu-button.tb-disabled:hover >.tb-text {
  color: rgba(0,0,0,0.35);
}
.tb-popup-menu-button.tb-disabled >.tb-line,
.tb-popup-menu-button.tb-disabled:hover >.tb-line {
  background-color: #e1e1e1;
}
.tb-popup-menu-button.tb-disabled >.tb-arrow,
.tb-popup-menu-button.tb-disabled:hover >.tb-arrow {
  background: url(img/dropdown_arrow_disabled.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-signature-button {
  display: inline-block;
  vertical-align: top;
  position: relative;
  max-width: 220px;
  height: 19px;
  line-height: 19px;
  font-size: 13px;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
}
.tb-popup-menu-signature-button >.tb-text {
  color: #eb8f50;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 22px;
}
.tb-popup-menu-signature-button >.tb-arrow {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: auto;
  left: auto;
  width: 19px;
  height: 19px;
  background: url(img/signature_dropdown_small_normal.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-signature-button:hover >.tb-text {
  color: #d16b36;
  text-decoration: underline;
}
.tb-popup-menu-signature-button:hover >.tb-arrow {
  background: url(img/signature_dropdown_small_hover.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-signature-button.tb-selected >.tb-text,
.tb-popup-menu-signature-button.tb-selected:hover >.tb-text {
  color: #eb8f50;
  text-decoration: none;
}
.tb-popup-menu-signature-button.tb-selected >.tb-arrow,
.tb-popup-menu-signature-button.tb-selected:hover >.tb-arrow {
  background: url(img/signature_dropdown_small_normal.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-content {
  background-color: #fff;
  border: 1px solid #b2b2b2;
  min-width: 144px;
  -moz-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  box-shadow: 0 7px 15px rgba(0,0,0,0.2);
}
.tb-popup-menu-content-expand {
  background-color: #fff;
  border: 1px solid #b2b2b2;
  min-width: max-content;
  -moz-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  box-shadow: 0 7px 15px rgba(0,0,0,0.2);
}
.tb-select-option,
.tb-popup-menu-item {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 27px;
  line-height: 27px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: inherit;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
.tb-select-option:hover,
.tb-popup-menu-item:hover {
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
}
.tb-select-option:active,
.tb-popup-menu-item:active {
  background-color: #e6e6e6;
  color: rgba(0,0,0,0.8);
}
.tb-select-option.tb-disabled,
.tb-popup-menu-item.tb-disabled {
  color: #e1e1e1;
  cursor: default;
}
.tb-select-option.tb-disabled:hover,
.tb-popup-menu-item.tb-disabled:hover {
  color: #e1e1e1;
  background-color: inherit;
}
.tb-select-option-selected {
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
}
.tb-select-option-count {
  position: absolute;
  right: 6px;
  text-align: right;
}
tr.tb-popup-menu-item {
  display: table-row;
}
.tb-popup-menu:focus .tb-popup-menu-button {
  border: 1px solid #666;
}
.tb-popup-menu:focus .tb-popup-menu-button >.tb-text {
  color: #333;
}
.tb-popup-menu:focus .tb-popup-menu-button >.tb-line {
  background-color: #666;
}
.tb-popup-menu:focus .tb-popup-menu-button >.tb-arrow {
  background: url(img/dropdown_arrow_pressed.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu:focus .tb-popup-menu-signature-button >.tb-text {
  color: #d16b36;
  text-decoration: underline;
}
.tb-popup-menu:focus .tb-popup-menu-signature-button >.tb-arrow {
  background: url(img/signature_dropdown_small_hover.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-indent {
  padding-left: 18px;
}
.tb-scroll.tb-tabs-banner-subplace-settings-banner {
  top: 36px;
}
.tb-scroll-settings {
  padding-bottom: 35px;
}
.tb-settings-banner {
  height: 35px;
  padding-left: 85px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}
.tb-settings-container {
  margin-left: 85px;
  width: 508px;
}
.tb-responsive-settings-container {
  margin-right: 85px;
  width: inherit;
}
.tb-settings-info-container {
  margin-left: 85px;
}
.tb-settings-section {
  padding-top: 35px;
  padding-bottom: 6px;
}
.tb-settings-group {
  padding-bottom: 18px;
}
.tb-settings-title {
  font-size: 20px;
  padding-bottom: 12px;
  color: #333;
}
.tb-settings-group-name {
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 12px;
  color: #333;
}
.tb-settings-subgroup-name {
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 12px;
  color: #333;
}
.tb-settings-group-name-message {
  padding-left: 10px;
  font-size: 12px;
  color: rgba(0,0,0,0.8);
  font-family: $headerFont;
}
.tb-settings-subheader {
  font-weight: normal;
  font-size: 12px;
  color: #333;
}
.tb-settings-control {
  padding-bottom: 6px;
  min-height: 23px;
}
.tb-settings-control span {
  cursor: pointer;
}
.tb-settings-control-with-message {
  padding-bottom: 6px;
  height: 35px;
}
.tb-settings-control-with-message span {
  cursor: pointer;
}
.tb-server-settings-control {
  padding-bottom: 18px;
  height: 23px;
}
.tb-server-settings-control span {
  cursor: pointer;
}
.tb-settings-control-with-warning {
  padding-bottom: 0;
}
.tb-settings-control-with-warning span {
  cursor: pointer;
}
.tb-settings-msg {
  padding-bottom: 12px;
}
.tb-settings-msg-pad-top {
  padding-bottom: 12px;
  padding-top: 12px;
}
.tb-settings-msg-italicized-light {
  padding-bottom: 12px;
  color: rgba(0,0,0,0.35);
  font-style: italic;
}
.tb-settings-separator {
  border-bottom: 1px solid #e6e6e6;
}
.tb-settings-form-actions {
  position: relative;
  display: inline-block;
  left: 500px;
  margin: 6px 0;
}
.tb-static-grid-table-settings-width {
  width: 508px;
}
.tb-static-grid-table-product-key-width {
  width: 1100px;
}
.tb-static-grid-table-settings-min-width {
  min-width: 508px;
}
.tb-static-grid-table-settings-max-width {
  max-width: 508px;
}
.tb-site-dialog {
  padding: 12px;
  height: 430px;
  width: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #b2b2b2;
}
.tb-settings-container .tb-site-url-preview {
  overflow: visible;
}
.tb-warning-text {
  color: #c41723;
  margin-top: 3px;
  padding-left: 21px;
}
.tb-language-locale-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 421.2px;
}
.tb-time-zone-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 421.2px;
}
.tb-data-grid-headers-line {
  min-width: 65px;
}
.settings-table-row-color0 {
  background-color: #fff;
}
.settings-table-row-color1 {
  background-color: #fff;
}
.tb-flex-space-between {
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.tb-settings-beta {
  display: inline-block;
  height: 16px;
  margin-left: 10px;
  font-size: 11px;
  color: #fff;
  padding: 2px 7px;
  background-color: #3d5f81;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tb-encryption-success {
  display: flex;
}
.tb-encryption-warning {
  color: #c41723;
  flex: 1;
}
.tb-encryption-stats {
  margin-right: 12px;
}
.tb-encryption-status-description {
  margin-right: 10px;
}
.tb-encryption-spinner {
  vertical-align: sub;
}
.tb-encryption-status {
  padding-left: 3px;
}
.tb-encryption-error {
  color: #c41723;
}
.encryption-settings-container {
  width: 385px;
}
.popover-encryption-tooltip {
  width: 185px;
  position: relative;
  top: -50px;
}
.ear-learn-more {
  display: block;
  position: relative;
  top: 10px;
}
.tb-settings-pad-bottom {
  padding-bottom: 12px;
}
.tb-setting-description-indent {
  margin-left: 21px;
}
span.sim-beta {
  border-radius: 2px;
  -o-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
  background: #eef5f9;
  padding: 3px 4px;
  height: 18px;
  width: 36px;
  flex-direction: column;
  font-size: 12px;
  font-family: 'BentonSans Medium';
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.2px;
  text-align: center;
  margin-right: 8px;
  color: #15557f;
}
