.tb-content-popup {
  width: 300px;
  height: 240px;
}
.tb-half {
  width: 50%;
  display: inline-block;
}
.tb-truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
