form {
  line-height: 1.5em;
}
form table {
  border-spacing: 0;
}
form .tb-disabled {
  color: rgba(0,0,0,0.35);
}
form .tb-enabled input.ng-invalid.ng-dirty,
form textarea.ng-invalid.ng-dirty {
  background: #fff;
}
form .tb-text-input-label-cell {
  vertical-align: top;
  padding-top: 5px;
  padding-right: 35px;
}
form .tb-input-wide {
  width: 300px;
}
form .tb-input-narrow {
  width: 70px;
}
form .tb-wide-overflow {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
form .tb-textarea {
  resize: none;
  margin: 0;
  outline: 0;
  padding: 6px;
  border: 1px solid #b2b2b2;
  background-color: #fff;
  color: rgba(0,0,0,0.8);
  font: inherit;
}
form .tb-textarea:hover {
  border: 1px solid #666;
  color: #333;
}
form .tb-textarea.tb-disabled,
form .tb-textarea.tb-disabled:hover {
  border-color: #e1e1e1;
  color: rgba(0,0,0,0.35);
}
form .tb-pop-up-menu-button-wide {
  min-width: 250px;
}
form .tb-form-error {
  color: #c41723;
}
.tb-block {
  display: block;
}
.tb-inline {
  display: inline-block;
}
.tb-input-wide-radio-button {
  display: inline-block;
  vertical-align: top;
  width: 150px;
}
