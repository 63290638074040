@media only screen and (max-width: 1250px) and (min-width: 560px) {
  .tb-page-header-search {
    position: absolute;
    right: 20px;
    z-index: 1;
  }
}
.tb-omnibox {
  display: inline-block;
  vertical-align: top;
  vertical-align: inherit;
  position: relative;
  text-align: left;
  width: 100%;
  max-width: initial;
  height: 32px;
}
.tb-omnibox.for-serp {
  width: 560px;
}
.tb-omnibox.for-serp .tb-omnibox-search-box {
  position: relative;
  display: block;
}
.tb-omnibox.for-serp .tb-omnibox-search-box .search {
  display: block;
  width: 100%;
}
.tb-omnibox.for-serp .tb-omnibox-search-box .search input {
  width: 100%;
}
.tb-omnibox.for-serp .tb-omnibox-press-enter-to-see-all {
  top: 0;
}
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box {
  display: flex;
  background-color: #fff;
}
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box.not-active-keyboard-navigation-item input:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box input {
  width: 370px;
  height: 32px;
  font-size: 14px;
  transition: width 0.25s ease-in-out;
  -o-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
  -khtml-transition: width 0.25s ease-in-out;
  background: #fff url(img/spaces_toolbar_search.svg?2023_3_25_mo7mxn7zi4) center no-repeat;
  background-position: 0px center;
  background-size: 32px 32px;
  padding-left: 36px;
}
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box input:hover:enabled,
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box input:focus {
  background-image: url(img/spaces_toolbar_search_active.svg?2023_3_25_mo7mxn7zi4) !important;
}
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box input:placeholder-shown {
  text-overflow: ellipsis;
}
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box .tb-omnibox-press-enter-to-see-all {
  padding-top: 7px;
  position: absolute;
  right: 14px;
  pointer-events: none;
  font-size: 14px;
}
.tb-omnibox .tb-omnibox-text-box .tb-omnibox-search-box-long input {
  width: 562px;
}
.tb-omnibox-popup {
  background-color: #fff;
  background-clip: padding-box;
  -moz-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  width: 560px;
}
.tb-omnibox-popup .tb-omnibox-no-results {
  border: 'none';
  padding: 18px;
}
.tb-omnibox-popup .tb-omnibox-no-results >.tb-omnibox-no-results-icon {
  background: url(img/magnifying_glass_extra_large.png?2023_3_25_mo7mxn7zi4) center no-repeat;
  height: 50px;
  padding: 6px;
}
.tb-omnibox-popup .tb-omnibox-no-results >.tb-omnibox-no-results-text {
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  padding: 6px;
}
.tb-omnibox-popup .tb-omnibox-magnifying-icon {
  background: url(img/spaces_toolbar_search.svg?2023_3_25_mo7mxn7zi4) center no-repeat;
  float: left;
  height: 30px;
  font-size: 14px;
  background-size: 32px 32px;
  padding: 0 15px 0 5px;
}
.tb-omnibox-popup .tb-omnibox-searchTerm-text {
  padding-left: 10px;
}
.tb-omnibox-popup .tb-omnibox-pending-spinner {
  padding-top: 18px;
  padding-bottom: 18px;
}
.tb-omnibox-popup .tb-omnibox-results {
  overflow: auto;
  border: none;
  padding: 0 3px;
  font-weight: normal;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed {
  position: relative;
  display: block;
  height: 29px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  margin: 5px 0 5px 1px;
  padding: 5px 4px 3px;
  color: rgba(0,0,0,0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed.tb-disabled:hover,
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed.tb-disabled.tb-selected {
  background-color: #f5f5f5;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed:hover {
  background-color: #f5f5f5;
  text-decoration: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed:hover >.tb-omnibox-see-all {
  text-decoration: underline;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed.tb-highlighted {
  background-color: #d4e1ed;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed.tb-selected {
  background-color: #d4e1ed;
  text-decoration: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-searchTerm-listed.tb-selected >.tb-omnibox-see-all {
  text-decoration: underline;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-cataloging-error-section {
  position: relative;
  display: block;
  height: 29px;
  line-height: 29px;
  padding: 14px 6px 14px 6px;
  color: rgba(0,0,0,0.8);
  text-align: center;
  font-size: 13px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section {
  overflow: hidden;
  margin-bottom: 12px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-menu-item-container:hover {
  color: #333;
  text-decoration: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-menu-item-container:hover .tb-omnibox-menu-item-name {
  text-decoration: underline;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section:first-child .tb-omnibox-header {
  border-top: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header {
  position: relative;
  display: block;
  height: 29px;
  line-height: 29px;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  margin: 0 5px 1px;
  padding: 5px 7px 3px;
  border-top: 1px solid #dadada;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header.tb-disabled:hover,
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header.tb-disabled.tb-selected {
  background-color: #f5f5f5;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header:hover {
  background-color: #f5f5f5;
  text-decoration: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header:hover >.tb-omnibox-see-all {
  text-decoration: underline;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header.tb-highlighted {
  background-color: #d4e1ed;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header.tb-selected {
  background-color: #d4e1ed;
  text-decoration: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header.tb-selected >.tb-omnibox-see-all {
  text-decoration: underline;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header .tb-omnibox-section-header-text {
  color: rgba(0,0,0,0.8);
  font-size: 16px;
  font-family: "BentonSans-Medium";
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header .tb-omnibox-section-header-count {
  padding-left: 12px;
  color: #666;
  font: normal 12px BentonSans-Book;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section .tb-omnibox-header .tb-omnibox-see-all {
  float: right;
  margin: 2px 0 0 0;
  font-size: 12px;
  font-weight: bold;
}
.tb-omnibox-popup .tb-omnibox-results .tb-horizontal-items {
  display: flex;
  margin: 2px 12px 0;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-metrics .tb-horizontal-items {
  justify-content: unset;
  -webkit-justify-content: unset;
}
.tb-omnibox-popup .tb-omnibox-results .tb-vertical-items {
  margin: 0 10px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-vertical-items .tb-omnibox-menu-item {
  height: 39px;
  margin: 7px 4px 15px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container {
  display: block;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container:hover .tb-omnibox-menu-item-highlight {
  background-color: #f5f5f5;
  display: block;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container.tb-highlighted .tb-omnibox-menu-item-highlight {
  background-color: #d4e1ed;
  display: block;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container.tb-selected .tb-omnibox-menu-item-highlight {
  background-color: #d4e1ed;
  display: block;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-highlight {
  position: absolute;
  left: -8px;
  right: -8px;
  top: -8px;
  bottom: -8px;
  display: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-content .tb-omnibox-popup-section-icon {
  color: #d8d8d8;
  border-radius: 2px;
  -o-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
  border: solid 2px #ebebeb;
  width: 164px;
  height: 102px;
  box-sizing: border-box;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-content .tb-omnibox-popup-section-icon.tb-omnibox-popup-workbook {
  color: #d8d8d8;
  position: relative;
  margin-bottom: 12px;
  border: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-content .tb-omnibox-popup-section-icon.tb-omnibox-popup-workbook [data-tb-test-id="thumbnailImage"] {
  -moz-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  position: absolute;
  width: 100%;
  height: 100%;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-content .tb-omnibox-popup-section-icon.tb-omnibox-popup-workbook .tb-omnibox-popup-workbook-icon-content {
  border-radius: 2px;
  -o-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
  border: solid 2px #ebebeb;
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-content .tb-omnibox-popup-section-icon.tb-omnibox-popup-workbook .tb-omnibox-popup-workbook-decorator {
  background-color: #fff;
  -moz-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  display: block;
  left: 7px;
  position: absolute;
  top: 7px;
  bottom: -4px;
  right: 7px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-content .tb-omnibox-popup-section-icon.tb-omnibox-popup-workbook .tb-omnibox-popup-workbook-decorator:before {
  background-color: #fff;
  -moz-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  content: '';
  display: block;
  left: -4px;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: 3px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item {
  position: relative;
  color: rgba(0,0,0,0.8);
  font-weight: bold;
  text-decoration: none;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-shrink: 0;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  background-size: contain;
  pointer-events: none;
  width: 36px;
  height: 36px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-datasources-certified {
  background-image: url(img/datasource_certified_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-datasources-not-certified {
  background-image: url(img/datasource_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-flows {
  background-image: url(img/flow_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-tables {
  background-image: url(img/table_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-tables-certified {
  background-image: url(img/table_certified_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-databases {
  background-image: url(img/server_36px-jumbo-copy.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-databases-certified {
  background-image: url(img/server_certified_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-dataRoles {
  background-image: url(img/datasource_role_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-lenses {
  background-image: url(img/lens_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-projects {
  background-image: url(img/folder_hollow_open_36px-jumbo.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-icon.tb-publishedConnections {
  background-image: url(img/spaces_search_published_connection.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details {
  flex: 1;
  -webkit-flex: 1;
  overflow: hidden;
  width: 165px;
  margin-top: 4px;
  padding-left: 3px;
  box-sizing: border-box;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-name {
  padding-right: 3px;
  line-height: 20px;
  font-size: 13px;
  font-weight: normal;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: 400 14px "BentonSans-Medium";
  margin-bottom: 2px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-owner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: normal 12px BentonSans-Book;
  margin-top: 3px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-owner >.tb-omnibox-menu-item-displayName {
  margin-left: 5px;
  font-weight: 400;
  font-family: "BentonSans-Medium";
  color: #666;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #4f4f4f;
  font-size: 12px;
  font-weight: normal;
  width: 100%;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats {
  height: 17px;
  line-height: 17px;
  padding-right: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: normal 12px 'BentonSans-Book';
  margin-top: 3px;
  margin-bottom: 2px;
  color: #666;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat {
  display: inline-block;
  padding-left: 8px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-favorite-text {
  vertical-align: middle;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-stat-text {
  vertical-align: middle;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-stat-icon {
  display: inline-block;
  vertical-align: top;
  background-position: center center;
  background-repeat: no-repeat;
  width: 17px;
  height: 17px;
  margin-right: 3px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-stat-icon.tb-favorite {
  background-image: url(img/favorite_selected_12px-xsmall.svg?2023_3_25_mo7mxn7zi4);
  margin-top: -1px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-stat-icon.tb-not-favorite {
  background-image: url(img/favorite_unselected_12px-xsmall.svg?2023_3_25_mo7mxn7zi4);
  margin-top: -1px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-stat-icon.tb-datasource {
  background-image: url(img/datasource_12px-xsmall.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-stat-icon.tb-sheet {
  background-image: url(img/view_12px-xsmall.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat >.tb-omnibox-menu-item-stat-icon.tb-workbook {
  background-image: url(img/workbook_12px-xsmall.svg?2023_3_25_mo7mxn7zi4);
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details >.tb-omnibox-menu-item-stats >.tb-omnibox-menu-item-stat-first {
  padding-left: 0px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item .tb-omnibox-menu-item-details-horizontal {
  margin-left: 5px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-vertical {
  height: 157px;
  width: 164px;
  flex-direction: column;
  margin-bottom: 14px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-vertical.tb-omnibox-menu-item-workbooks {
  height: 165px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-horizontal .tb-omnibox-menu-item-content {
  display: flex;
  align-items: center;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-user {
  height: 57px;
  margin: 4px 4px 11px 4px;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-metrics {
  height: 60px;
  width: 250px;
  margin: 0 12px 12px 0;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-metrics .tb-omnibox-menu-item-content {
  align-items: unset;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-metrics .tb-omnibox-popup-metrics-icon {
  color: #d8d8d8;
  width: 84px;
  height: 70px;
  position: relative;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-metrics .tb-omnibox-popup-metrics-icon >div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  height: 100%;
}
.tb-omnibox-popup .tb-omnibox-results .tb-omnibox-section-items .tb-omnibox-menu-item-container .tb-omnibox-menu-item-metrics .tb-omnibox-menu-item-details {
  height: 70px;
}
.tb-omnibox-status {
  position: absolute;
  top: -100px;
}
