.tb-remote-agent-presence-icon {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  -o-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  margin-right: 6px;
}
.tb-remote-agent-presence-icon.tb-remote-agent-presence-connected {
  background-color: #2fa37d;
}
.tb-remote-agent-presence-icon.tb-remote-agent-presence-not-connected {
  background-color: #a0131c;
}
.tb-remote-agent-presence-icon.tb-remote-agent-presence-warning {
  background-color: #eb8f50;
}
.tb-data-grid-bridge-center {
  text-align: center;
}
.tb-data-grid-cell-text-bridge {
  color: rgba(0,0,0,0.8);
  height: 52px;
  width: 130px;
  line-height: 51px;
  white-space: nowrap;
  font-size: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tb-data-grid-cell-text-bridge-empty {
  color: #fff;
  height: 30px;
  width: 130px;
}
.tb-data-grid-cell-text-bridge-loadbalanced {
  color: #c41723;
}
.tb-data-grid-header-text {
  font-size: 13px;
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: -5px center;
}
.tb-data-grid-header-text.tb-sort-asc {
  background-image: url(img/sort_arrow_ascending.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-header-text.tb-sort-desc {
  background-image: url(img/sort_arrow_descending.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-header-text-normal {
  font-size: 13px;
  padding-left: 12px;
  padding-right: 12px;
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: -5px center;
}
.tb-data-grid-header-info-icon {
  display: inline-block;
  margin-left: -9px;
  width: 14px;
  height: 14px;
  vertical-align: text-top;
  background-repeat: no-repeat;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-header-info-icon:hover {
  background-image: url(img/info_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-title-text {
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 6px;
}
.tb-settings-subgroup-name-bridge {
  padding-top: 12px;
}
.tb-add-new-bridge-client {
  font-size: 12px;
  font-weight: normal;
}
.tb-remote-agent-upgrade-warning-icon {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 6px;
  background-image: url(img/remote_agent_upgrade_required.svg?2023_3_25_mo7mxn7zi4);
  vertical-align: text-top;
  background-repeat: no-repeat;
}
.tb-static-grid-table-remote-agents-width {
  width: 1000px;
}
