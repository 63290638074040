.tb-place-banner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  height: 163px;
  background-color: #fff;
  border-bottom: 3px solid #e6e6e6;
}
.tb-subplace {
  position: absolute;
  top: 166px;
  right: 0;
  bottom: 0;
  left: 0;
}
.tb-tabs-banner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  height: 60px;
  background-color: #fff;
  border-bottom: 3px solid #e6e6e6;
}
.tb-tabs-banner-subplace {
  position: absolute;
  top: 63px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.tb-stylish-settings {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tb-stylish-settings .tb-tabs-banner-subplace {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.tb-place-information {
  position: absolute;
  top: 58px;
  right: 0;
  bottom: auto;
  left: 0;
  height: 55px;
  margin-left: 6px;
  margin-right: 18px;
  white-space: nowrap;
  font-size: 12px;
}
.tb-place-information .tb-place-info {
  margin-right: 12px;
  white-space: normal;
  color: #333;
}
.tb-place-information .tb-place-info-item-label {
  padding-right: 6px;
  color: #999;
}
.tb-place-type {
  float: left;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  border-right: 1px solid #e6e6e6;
  margin-right: 12px;
}
.tb-place-type-icon {
  float: left;
  margin-right: 12px;
  min-width: 60px;
  height: 60px;
  margin-top: -2px;
  background-repeat: no-repeat;
  background-position: center center;
}
.tb-place-type-icon.tb-place-type-icon-flow {
  background-image: url(img/flow_content_type.svg?2023_3_25_mo7mxn7zi4);
}
.tb-place-type-icon.tb-place-type-icon-project {
  background-image: url(img/project_content_type.svg?2023_3_25_mo7mxn7zi4);
}
.tb-place-type-icon.tb-place-type-icon-workbook {
  background-image: url(img/workbook_content_type.svg?2023_3_25_mo7mxn7zi4);
}
.tb-place-type-icon.tb-place-type-icon-datasource {
  background-image: url(img/data_source_content_type.svg?2023_3_25_mo7mxn7zi4);
}
.tb-place-type-icon.tb-place-type-icon-group {
  background-image: url(img/group_content_type.svg?2023_3_25_mo7mxn7zi4);
}
.tb-place-type-icon.tb-place-type-icon-schedule {
  background-image: url(img/schedule_content_type.svg?2023_3_25_mo7mxn7zi4);
}
.tb-place-type-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
}
.tb-place-type-text-inline {
  text-transform: uppercase;
}
.tb-place-name-and-description {
  margin-left: 12px;
}
.tb-place-name-line {
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
}
.tb-place-description-line {
  margin-top: 5px;
  line-height: 19px;
}
.tb-place-description-line-text {
  display: inline-block;
  vertical-align: top;
}
.tb-place-description-line-text-value {
  display: inline-block;
  vertical-align: top;
  margin-left: 6px;
  color: #999;
}
.tb-place-description-flow-links {
  display: inline-block;
  vertical-align: top;
}
.tb-place-description-line-owner {
  max-width: 200px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-place-description-line-hits {
  max-width: 80px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-place-description-line-favorites {
  max-width: 80px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-place-description-line-separator {
  color: #999;
  display: inline-block;
  vertical-align: top;
  margin: 0 9px;
}
.tb-place-favorite .tb-favorite-button {
  display: inline-block;
  margin: -12px 0 0 0;
  vertical-align: middle;
}
.tb-place-name {
  float: left;
  max-width: 70%;
  height: 32px;
  line-height: 32px;
  font-size: 28px;
  font-weight: normal;
  color: #4e4e4e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-place-name.tb-datasource-place-name {
  max-width: 40%;
}
.tb-place-name.tb-workbook-place-name {
  max-width: 50%;
}
.tb-place-description {
  white-space: nowrap;
}
.tb-place-description-text-holder {
  display: inline-block;
  vertical-align: top;
  max-width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-place-description-full-width .tb-place-description-text-holder {
  max-width: 750px;
}
.tb-place-description-read-more {
  display: inline-block;
  vertical-align: top;
  padding-left: 12px;
}
.tb-subplace-tabs {
  position: absolute;
  top: auto;
  right: 18px;
  bottom: 0;
  left: 12px;
  height: 41px;
  white-space: nowrap;
  overflow: initial;
}
.tb-subplace-tabs.tb-small {
  height: 31px;
}
.tb-tabs-banner-title {
  display: inline-block;
  vertical-align: top;
  height: 41px;
  text-decoration: none;
  position: absolute;
  top: auto;
  right: 18px;
  bottom: 0;
  left: 18px;
}
.tb-tabs-banner-title >.tb-label {
  display: inline-block;
  vertical-align: baseline;
  height: 41px;
  line-height: 41px;
  font-weight: fongWeight;
  font-size: 18px;
  color: #333;
  border-bottom: 3px solid transparent;
  margin-right: 6px;
}
.tb-tabs-banner-title >.tb-count {
  display: inline-block;
  vertical-align: baseline;
  height: 41px;
  line-height: 41px;
  font-size: 12px;
  border-bottom: 3px solid transparent;
  padding-left: 6px;
  color: rgba(0,0,0,0.56);
  padding-right: 6px;
}
.tb-tabs-banner-title >.tb-label,
.tb-tabs-banner-title >.tb-count {
  color: #333;
}
.tb-tabs-banner-title.tb-clickable {
  cursor: pointer;
}
.tb-tabs-banner-title-small {
  display: inline-block;
  vertical-align: top;
  height: 31px;
  text-decoration: none;
  position: absolute;
  top: auto;
  right: 18px;
  bottom: 0;
  left: 18px;
}
.tb-tabs-banner-title-small >.tb-label {
  display: inline-block;
  vertical-align: baseline;
  height: 31px;
  line-height: 31px;
  font-weight: fongWeight;
  font-size: 15px;
  color: #333;
  border-bottom: 3px solid transparent;
  margin-right: 6px;
}
.tb-tabs-banner-title-small >.tb-count {
  display: inline-block;
  vertical-align: baseline;
  height: 31px;
  line-height: 31px;
  font-size: 12px;
  border-bottom: 3px solid transparent;
  padding-left: 6px;
  color: rgba(0,0,0,0.56);
  padding-right: 6px;
}
.tb-tabs-banner-title-small >.tb-label,
.tb-tabs-banner-title-small >.tb-count {
  color: #333;
}
.tb-tabs-banner-title-small.tb-clickable {
  cursor: pointer;
}
.tb-details {
  height: 100%;
  overflow: auto;
  background-color: #fff;
}
.tb-details > table {
  width: 100%;
  max-width: 768px;
  padding-right: 18px;
  margin-top: 12px;
  border-spacing: 0;
}
.tb-details > table > tbody > tr {
  vertical-align: top;
}
.tb-details > table > tbody > tr > td {
  padding: 12px;
}
.tb-details > table .tb-label {
  width: 1%;
  padding-left: 18px;
  text-align: right;
  font-size: 15px;
  font-weight: normal;
  color: #999;
  line-height: 23px;
  white-space: nowrap;
}
.tb-details > table .tb-details-value {
  width: 100%;
  padding-top: 15px;
  border-bottom: 1px solid #e6e6e6;
  line-height: 1.35em;
}
.tb-details > table .tb-details-value.tb-details-cell-no-border {
  border: 0;
}
.tb-details > table .tb-details-value.tb-radio-button-control {
  line-height: 0;
}
.tb-details > table .tb-details-value .tb-create-schedule-form-type {
  height: 164px;
}
.tb-details > table .tb-details-value .tb-disabled {
  color: rgba(0,0,0,0.35);
}
.tb-details > table .tb-details-button-cell {
  width: 1%;
  border-bottom: 1px solid #e6e6e6;
}
.tb-details > table .tb-details-button-cell .tb-outline-button {
  margin-top: 6px;
}
.tb-details > table .tb-details-button-cell .tb-outline-button:first-child {
  margin-top: 0;
}
.tb-details > table .tb-details-button-cell.tb-details-cell-no-border {
  border: 0;
}
.tb-details .tb-about {
  vertical-align: top;
}
.tb-details .tb-details-button-cell .tb-outline-button {
  display: block;
  text-align: center;
  margin-top: 6px;
}
.tb-details .tb-details-button-cell .tb-outline-button:first-child {
  margin-top: 0;
}
.tb-details .tb-details-button-cell .tb-outline-button.tb-margin-top-wide {
  margin-top: 12px;
}
.tb-details .tb-details-button-cell button {
  width: 100%;
}
.tb-details .tb-editable-textarea textarea {
  width: 100%;
  box-sizing: border-box;
}
.tb-details .tb-tag-row {
  vertical-align: top;
}
.tb-details-header {
  padding: 6px;
  background-color: #fff;
  height: 25px;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
}
.tb-details-header-extract {
  margin-left: 18px;
  display: inline-block;
}
.tb-details-header-title {
  color: #333;
  font-weight: bold;
  display: inline-block;
  height: 25px;
  line-height: 25px;
}
.tb-details-header-main {
  padding-left: 18px;
}
.tb-details-header-sub {
  padding: 6px;
  padding-right: 18px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  text-align: right;
  height: 25px;
  line-height: 25px;
}
.tb-details-header-label {
  color: #333;
  font-weight: bold;
  margin-right: 6px;
}
.tb-more-actions .tb-inline-buttons {
  text-align: right;
}
