.tb-app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  font-weight: normal;
  font-size: 12px;
  color: rgba(0,0,0,0.8);
  line-height: 1.5em;
}
.tb-main-constrained {
  min-width: 768px;
  min-height: 560px;
  overflow: hidden;
}
.tb-app-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
.tb-app-inner .tb-bottom,
.tb-app-inner .tb-main-content {
  z-index: 0;
}
.tb-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f5f5;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=33)';
  filter: alpha(opacity=33);
  opacity: 0.33;
}
.tb-backdrop.tb-backdrop-dark {
  background-color: #000;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=75)';
  filter: alpha(opacity=75);
  opacity: 0.75;
}
.tb-backdrop-white {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
}
[id*="-space-tabs-tab"] {
  flex-shrink: 0;
}
