.tb-checkbox {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  width: 15px;
  height: 15px;
  outline: 0;
  color: rgba(0,0,0,0.8);
  background-size: 15px 15px;
  background-position: left top;
  background-repeat: no-repeat;
  background-image: url(img/checkbox_empty_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-checkbox:hover,
.tb-checkbox:focus {
  background-image: url(img/checkbox_empty_hover.svg?2023_3_25_mo7mxn7zi4);
  color: #333;
}
.tb-checkbox.tb-disabled {
  background-image: url(img/checkbox_empty_disabled.svg?2023_3_25_mo7mxn7zi4);
  cursor: default;
  color: rgba(0,0,0,0.35);
}
.tb-checkbox.tb-checked {
  background-image: url(img/checkbox_selected_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-checkbox.tb-checked:hover,
.tb-checkbox.tb-checked:focus {
  background-image: url(img/checkbox_selected_hover.svg?2023_3_25_mo7mxn7zi4);
  color: #333;
}
.tb-checkbox.tb-checked.tb-disabled {
  background-image: url(img/checkbox_selected_disabled.svg?2023_3_25_mo7mxn7zi4);
  cursor: default;
  color: rgba(0,0,0,0.35);
}
.tb-checkbox.tb-mixed {
  background-image: url(img/checkbox_mixed_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-checkbox.tb-mixed:hover,
.tb-checkbox.tb-mixed:focus {
  background-image: url(img/checkbox_mixed_hover.svg?2023_3_25_mo7mxn7zi4);
  color: #333;
}
.tb-checkbox.tb-mixed.tb-disabled {
  background-image: url(img/checkbox_mixed_disabled.svg?2023_3_25_mo7mxn7zi4);
  cursor: default;
  color: rgba(0,0,0,0.35);
}
.tb-checkbox.tb-with-label {
  width: auto;
  height: auto;
  padding-left: 21px;
  line-height: 15px;
}
.tb-checkbox-label {
  margin-left: 6px;
  line-height: 15px;
}
.tb-checkbox-cell {
  display: inline-block;
  vertical-align: top;
  width: 15px;
  height: 15px;
  padding-top: 11px;
  padding-left: 11px;
  padding-bottom: 11px;
  padding-right: 11px;
}
.tb-checkbox-site-grid-cell {
  display: inline-block;
  vertical-align: top;
  width: 15px;
  height: 15px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 11px;
}
.tb-site-grid-checkbox-cell {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.taller-rows .tb-checkbox-cell {
  width: 15px;
  height: 15px;
  padding-top: 14px;
  padding-left: 14px;
  padding-bottom: 15px;
  padding-right: 15px;
}
