.tb-popup-menu-vertical-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 313.2px;
}
.tb-popup-menu {
  position: relative;
}
.tb-popup-menu:focus {
  outline: 0;
}
.tb-popup-menu.tb-popup-menu-inline {
  display: inline-block;
}
.tb-popup-menu.tb-popup-menu-inline >div {
  display: inline-block;
}
.tb-popup-menu-button {
  position: relative;
  height: 21px;
  line-height: 21px;
  border: 1px solid #b2b2b2;
  padding-left: 6px;
  background-color: #fff;
  cursor: pointer;
}
.tb-popup-menu-button >.tb-text {
  color: rgba(0,0,0,0.8);
  padding-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-popup-menu-button >.tb-line {
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  left: auto;
  width: 1px;
  background-color: #b2b2b2;
}
.tb-popup-menu-button >.tb-arrow {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 1px;
  left: auto;
  width: 19px;
  height: 19px;
  background: url(img/dropdown_arrow_normal.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-button:hover {
  border: 1px solid #666;
}
.tb-popup-menu-button:hover >.tb-text {
  color: #333;
}
.tb-popup-menu-button:hover >.tb-line {
  background-color: #666;
}
.tb-popup-menu-button:hover >.tb-arrow {
  background: url(img/dropdown_arrow_pressed.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-button.tb-selected,
.tb-popup-menu-button.tb-selected:hover {
  border: 1px solid #b2b2b2;
}
.tb-popup-menu-button.tb-selected >.tb-text,
.tb-popup-menu-button.tb-selected:hover >.tb-text {
  color: rgba(0,0,0,0.8);
}
.tb-popup-menu-button.tb-selected >.tb-line,
.tb-popup-menu-button.tb-selected:hover >.tb-line {
  background-color: #b2b2b2;
}
.tb-popup-menu-button.tb-selected >.tb-arrow,
.tb-popup-menu-button.tb-selected:hover >.tb-arrow {
  background: url(img/dropdown_arrow_normal.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-button.tb-disabled,
.tb-popup-menu-button.tb-disabled:hover {
  cursor: default;
  border: 1px solid #e1e1e1;
}
.tb-popup-menu-button.tb-disabled >.tb-text,
.tb-popup-menu-button.tb-disabled:hover >.tb-text {
  color: rgba(0,0,0,0.35);
}
.tb-popup-menu-button.tb-disabled >.tb-line,
.tb-popup-menu-button.tb-disabled:hover >.tb-line {
  background-color: #e1e1e1;
}
.tb-popup-menu-button.tb-disabled >.tb-arrow,
.tb-popup-menu-button.tb-disabled:hover >.tb-arrow {
  background: url(img/dropdown_arrow_disabled.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-signature-button {
  display: inline-block;
  vertical-align: top;
  position: relative;
  max-width: 220px;
  height: 19px;
  line-height: 19px;
  font-size: 13px;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
}
.tb-popup-menu-signature-button >.tb-text {
  color: #eb8f50;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 22px;
}
.tb-popup-menu-signature-button >.tb-arrow {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: auto;
  left: auto;
  width: 19px;
  height: 19px;
  background: url(img/signature_dropdown_small_normal.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-signature-button:hover >.tb-text {
  color: #d16b36;
  text-decoration: underline;
}
.tb-popup-menu-signature-button:hover >.tb-arrow {
  background: url(img/signature_dropdown_small_hover.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-signature-button.tb-selected >.tb-text,
.tb-popup-menu-signature-button.tb-selected:hover >.tb-text {
  color: #eb8f50;
  text-decoration: none;
}
.tb-popup-menu-signature-button.tb-selected >.tb-arrow,
.tb-popup-menu-signature-button.tb-selected:hover >.tb-arrow {
  background: url(img/signature_dropdown_small_normal.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu-content {
  background-color: #fff;
  border: 1px solid #b2b2b2;
  min-width: 144px;
  -moz-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  box-shadow: 0 7px 15px rgba(0,0,0,0.2);
}
.tb-popup-menu-content-expand {
  background-color: #fff;
  border: 1px solid #b2b2b2;
  min-width: max-content;
  -moz-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 7px 15px rgba(0,0,0,0.2);
  box-shadow: 0 7px 15px rgba(0,0,0,0.2);
}
.tb-select-option,
.tb-popup-menu-item {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 27px;
  line-height: 27px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: inherit;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
.tb-select-option:hover,
.tb-popup-menu-item:hover {
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
}
.tb-select-option:active,
.tb-popup-menu-item:active {
  background-color: #e6e6e6;
  color: rgba(0,0,0,0.8);
}
.tb-select-option.tb-disabled,
.tb-popup-menu-item.tb-disabled {
  color: #e1e1e1;
  cursor: default;
}
.tb-select-option.tb-disabled:hover,
.tb-popup-menu-item.tb-disabled:hover {
  color: #e1e1e1;
  background-color: inherit;
}
.tb-select-option-selected {
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
}
.tb-select-option-count {
  position: absolute;
  right: 6px;
  text-align: right;
}
tr.tb-popup-menu-item {
  display: table-row;
}
.tb-popup-menu:focus .tb-popup-menu-button {
  border: 1px solid #666;
}
.tb-popup-menu:focus .tb-popup-menu-button >.tb-text {
  color: #333;
}
.tb-popup-menu:focus .tb-popup-menu-button >.tb-line {
  background-color: #666;
}
.tb-popup-menu:focus .tb-popup-menu-button >.tb-arrow {
  background: url(img/dropdown_arrow_pressed.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-popup-menu:focus .tb-popup-menu-signature-button >.tb-text {
  color: #d16b36;
  text-decoration: underline;
}
.tb-popup-menu:focus .tb-popup-menu-signature-button >.tb-arrow {
  background: url(img/signature_dropdown_small_hover.png?2023_3_25_mo7mxn7zi4) no-repeat;
}
