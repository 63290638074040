.tb-user-authentication-options .tb-outline-button {
  width: 100%;
  margin-bottom: 5px;
  display: block;
}
table.tb-user-authentication-options tr {
  vertical-align: middle;
  text-align: left;
}
table.tb-user-authentication-options tr td {
  padding-bottom: 5px;
}
table.tb-user-authentication-options td.tb-button .tb-outline-button {
  width: 100%;
}
table.tb-user-authentication-options td.tb-description {
  white-space: normal;
}
