.tb-dropdown {
  position: relative;
  height: 23px;
}
.tb-dropdown-button {
  position: relative;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #b2b2b2;
  background-color: #fff;
  color: #666;
  padding: 1px 7px;
  cursor: pointer;
}
.tb-dropdown-button:hover,
.tb-dropdown-button:focus {
  border: 1px solid #666;
  color: #333;
}
.tb-dropdown-button.tb-selected:hover {
  border: 1px solid #b2b2b2;
  color: #666;
}
.tb-dropdown-text-selection {
  height: 19px;
  line-height: 19px;
  margin-left: -6px;
  margin-right: -6px;
  padding-left: 6px;
  padding-right: 6px;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-dropdown-text-selection.tb-clearable {
  padding-right: 29px;
}
.tb-dropdown-text-selection.tb-placeholder {
  color: #c0c0c0;
}
.tb-dropdown-list {
  position: absolute;
  height: 149.5px;
  top: 0;
  left: 0;
  background-color: #fff;
}
.tb-dropdown-list .tb-dropdown-list-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #b2b2b2;
}
.tb-dropdown-list .tb-dropdown-list-inner .tb-scroll {
  margin: 3px;
}
.tb-dropdown-list strong {
  font-style: normal;
  text-decoration: underline;
}
.tb-row-count {
  text-align: right;
  min-width: 37px;
}
