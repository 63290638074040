.tb-textile {
  line-height: 1.5em;
  white-space: normal;
  word-wrap: break-word;
}
.tb-textile p:first-child {
  margin: 0;
}
.tb-textile a {
  color: #1a699e;
}
.tb-textile img {
  max-width: 100%;
}
.tb-textile-editor-textarea {
  outline: 0;
  font: inherit;
  border: 1px solid #b2b2b2;
  padding: 6px;
  resize: vertical;
  line-height: 1.5em;
  height: 300px;
}
.tb-textile-preview-container {
  max-height: 300px;
  overflow: auto;
}
