.tb-worker-statuses-container {
  white-space: nowrap;
  min-height: 37px;
  line-height: 37px;
}
.tb-worker-statuses-container .tb-data-grid-icon {
  display: inline-block;
  padding-right: 3px;
}
.tb-worker-statuses-container .tb-data-grid-icon .tb-icon-process-status {
  height: inherit;
}
.tb-worker-statuses-container .tb-process-preferred-label {
  padding-left: 2px;
  color: rgba(0,0,0,0.56);
}
.tb-server-status-grid-container .tb-data-grid-separator-row {
  vertical-align: top;
}
.tb-status-controls-container {
  width: 508px;
  position: relative;
  padding-top: 6px;
  white-space: nowrap;
}
.tb-status-legend {
  margin-left: 35px;
  height: 23px;
  line-height: 23px;
  white-space: nowrap;
}
.tb-status-legend-item-label {
  display: inline-block;
  vertical-align: top;
}
.tb-status-legend-item {
  margin-right: 12px;
}
.tb-status-legend-item .tb-icon-process-status {
  display: inline-block;
  height: 23px;
  margin-right: 3px;
}
.tb-ziplog-date-col {
  width: 165px;
}
.tb-ziplog-size-col {
  width: 70px;
}
