.tb-user-settings {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: #fff;
}
.tb-user-settings table {
  border-spacing: 0;
}
.tb-user-settings table.tb-root-table {
  margin-top: 18px;
}
.tb-user-settings table tbody tr {
  vertical-align: top;
}
.tb-user-settings table tbody tr td,
.tb-user-settings table tbody tr .tb-label-multiline {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  height: 23px;
}
.tb-user-settings table tbody tr td.tb-nested-table,
.tb-user-settings table tbody tr .tb-label-multiline.tb-nested-table {
  padding: 0;
}
.tb-user-settings table tbody tr.tb-divider > td {
  padding: 0;
  height: 0;
}
.tb-user-settings table tbody tr.tb-divider > td .tb-line {
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: #e6e6e6;
  height: 1px;
}
.tb-user-settings table .tb-label {
  padding-left: 18px;
  text-align: right;
  font-size: 15px;
  font-weight: normal;
  color: #999;
  vertical-align: top;
  min-width: 242px;
}
.tb-user-settings table .tb-details-value {
  width: 500px;
  font-size: 12px;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-user-settings table .tb-details-value-multi-line {
  width: 500px;
  font-size: 12px;
  max-width: 500px;
  white-space: normal;
}
.tb-user-settings table .tb-details-button-cell .tb-outline-button {
  min-width: 156px;
  float: right;
  text-align: center;
  margin-top: 6px;
}
.tb-user-settings table .tb-details-button-cell .tb-outline-button:first-child {
  margin-top: 0;
}
.tb-user-settings table .tb-popup-menu-button {
  min-width: 250px;
}
.tb-oauth-table .tb-credential-type-header {
  padding: 5px;
}
.tb-oauth-table .tb-credential-type-header .tb-credential-type-title {
  font-weight: bold;
  display: inline-block;
  width: 220px;
  margin-left: 3px;
}
.tb-oauth-table .tb-oauth-type-header {
  padding: 5px;
  background-color: #dadada;
}
.tb-oauth-table .tb-oauth-type-header .tb-oauth-type-title {
  font-weight: bold;
  display: inline-block;
  width: 220px;
}
.tb-oauth-table .tb-oauth-type-header .tb-oauth-type-add {
  font-size: 12px;
}
.tb-oauth-table .tb-oauth-type-row {
  margin: 3px 6px 3px 12px;
}
.tb-oauth-table .tb-oauth-type-row .tb-oauth-type-row-details,
.tb-oauth-table .tb-oauth-type-row .tb-oauth-test-results {
  display: inline-block;
  vertical-align: top;
}
.tb-oauth-table .tb-oauth-type-row .tb-oauth-type-username {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-oauth-table .tb-oauth-type-row .tb-oauth-type-username,
.tb-oauth-table .tb-oauth-type-row .tb-oauth-type-delete,
.tb-oauth-table .tb-oauth-type-row .tb-oauth-type-test {
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
}
.tb-oauth-table .tb-oauth-type-row .tb-oauth-test-message {
  display: inline-block;
  width: 300px;
  white-space: normal;
}
.tb-edit-language-and-locale .tb-select {
  display: inline-block;
  vertical-align: top;
}
.tb-edit-time-zone .tb-select {
  display: inline-block;
  vertical-align: top;
}
.tb-user-settings-connected-device-info-icon {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  margin-left: 6px;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-user-settings-connected-device-info-icon:hover {
  background-image: url(img/info_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-user-settings-item-table {
  font-size: 12px;
  vertical-align: top;
}
.tb-user-settings-item-table table {
  border-spacing: 0;
}
.tb-user-settings-item-table table > tbody > tr {
  vertical-align: middle;
}
.tb-user-settings-item-table table > tbody > tr > td {
  padding: 0;
}
.tb-user-settings-item-table table > tbody > tr .tb-user-settings-item-name {
  max-width: 254px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
