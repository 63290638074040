.tb-dialog-title {
  font-size: 20px;
  font-weight: normal;
  color: #333;
  margin-bottom: 18px;
}
.tb-dialog-title.are-you-really-sure {
  text-align: center;
  font-weight: bold;
}
.tb-dialog-body-text {
  word-wrap: break-word;
  line-height: 1.5em;
}
.tb-dialog-body-text.are-you-really-sure {
  text-align: center;
}
.tb-dialog-description {
  font-size: 12px;
  margin-bottom: 18px;
  word-wrap: break-word;
}
.tb-dialog-actions {
  margin-top: 13.5px;
  white-space: nowrap;
  text-align: right;
}
.tb-dialog-actions.are-you-really-sure {
  text-align: center;
}
.tb-dialog-error {
  margin-top: 12px;
  color: #c41723;
}
.tb-dialog-body-input {
  width: 100%;
  margin-top: 12px;
}
.tb-dialog-body-control {
  margin-top: 12px;
}
.tb-alert-message {
  margin-bottom: 12px;
}
.tb-dialog-body-input-description {
  margin-bottom: 6px;
  word-wrap: break-word;
}
.tb-checkbox-group-outside {
  position: absolute;
  top: auto;
  right: 0;
  bottom: auto;
  left: auto;
  color: #fff;
}
