.tb-quicklinks {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style: none;
  justify-content: space-around;
  width: 100%;
}
.tb-quicklinks-section {
  margin: 0px 8px;
  padding: 8px 0px;
  transition: height 0.3s;
}
.tb-quicklinks-section.tb-loading {
  height: 137px;
}
.tb-quicklinks-section.tb-empty {
  height: 0;
  margin: 0;
  padding: 0;
  border-top: none;
}
.tb-quicklinks-section-divider {
  border-top: 1px solid #e6e6e6;
}
.tb-quicklinks-pending-spinner {
  margin: 0px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tb-quicklinks-row {
  display: table;
  margin: 4px 0 0 0;
  width: 100%;
  align-items: stretch;
}
.tb-quicklinks-list {
  align-items: top;
  padding: 8px 0 8px 0;
}
.tb-quicklinks-header {
  font: BentonSans-Book-Regular;
  font-weight: bold;
  font-size: 14px;
  color: #666;
  height: 14px;
  vertical-align: top;
  horizontal-align: left;
}
.tb-quicklinks-list-item {
  display: flex;
  align-items: top;
}
.tb-quicklinks-list-item-link {
  display: flex;
  text-align: center;
  width: 100%;
  padding: 5px 5px 5px 5px;
  flex: 29;
}
.tb-quicklinks-list-item-link >span {
  text-align: center;
  color: #666;
}
.tb-quicklinks-list-item-link >img {
  height: 18px;
}
.tb-quicklinks-list-item-button {
  visibility: hidden;
  flex: 1;
  border: 0px;
  background-color: transparent;
  margin: 0;
  vertical-align: center;
  background-position: 8px 7px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-image: url(img/clear_14px-small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-quicklinks-list-item-button:hover {
  cursor: pointer;
  outline: 0;
  background-image: url(img/clear_focus_14px-small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-quicklinks-highlight {
  text-align: center;
  display: inline-block;
  width: 75px;
  padding: 14px 15px 10px 15px;
  margin: 0;
  color: #666;
  vertical-align: top;
  height: 100%;
}
.tb-quicklinks-highlight.activeSectionItem {
  background-color: #d4e1ed;
}
.tb-quicklinks-highlight >div {
  margin: auto;
  height: 40px;
  width: 40px;
}
.tb-quicklinks-highlight >div >img {
  height: 20px;
  margin: 10px;
}
.tb-quicklinks-placeholder {
  display: table-cell;
  width: 20%;
  padding: 14px 15px 10px 15px;
  margin: 8px 0 0 0;
}
.tb-quicklinks-highlight-border {
  border: 1px solid #bfbfbf;
  border-radius: 50%;
}
.tb-quicklinks-highlight:hover,
.tb-quicklinks-highlight:focus {
  background-color: #f5f5f5;
  color: #666;
  text-decoration: none;
}
.tb-quicklinks-list-item-hover-focus {
  background-color: #f5f5f5;
}
.tb-quicklinks-list-item-link:hover,
.tb-quicklinks-list-item-link:focus {
  color: #666;
  text-decoration: none;
}
