.tb-col-drag-target {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: -3px;
  right: -3px;
  background-color: transparent;
  cursor: col-resize;
}
.tb-row-drag-target {
  position: absolute;
  z-index: 1;
  top: -3px;
  bottom: -3px;
  left: 0;
  right: 0;
  background-color: transparent;
  cursor: row-resize;
}
