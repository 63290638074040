.tb-hidden {
  visibility: hidden;
}
.tb-relative {
  position: relative;
}
.tb-absolute {
  position: absolute;
}
.tb-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.tb-fill-centered {
  display: flex;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.tb-fill-width {
  position: absolute;
  left: 0;
  right: 0;
}
.tb-flex-fill {
  overflow-y: auto;
  position: relative;
  flex: 1;
  -webkit-flex: 1;
}
.tb-column-flex-parent {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}
.tb-row-flex-parent {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
}
.tb-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.tb-top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.tb-top-1-left {
  position: absolute;
  top: 1px;
  left: 0;
}
.tb-top-left-1 {
  position: absolute;
  top: 0;
  left: 1px;
}
.tb-top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.tb-top-1-right {
  position: absolute;
  top: 1px;
  right: 0;
}
.tb-top-right-1 {
  position: absolute;
  top: 0;
  right: 1px;
}
.tb-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.tb-bottom-1-left {
  position: absolute;
  bottom: 1px;
  left: 0;
}
.tb-bottom-left-1 {
  position: absolute;
  bottom: 0;
  left: 1px;
}
.tb-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.tb-bottom-1-right {
  position: absolute;
  bottom: 1px;
  right: 0;
}
.tb-bottom-right-1 {
  position: absolute;
  bottom: 0;
  right: 1px;
}
