.tb-editable-textarea-content .tb-editable-textarea-textarea-wrapper {
  margin: -7px -7px auto -7px;
}
.tb-editable-textarea-bottom {
  position: relative;
  padding-top: 6px;
}
.tb-character-counter {
  float: right;
}
.tb-character-counter.tb-exceeded-length {
  color: #c41723;
}
