.tb-box {
  display: inline-block;
  vertical-align: top;
}
.tb-row-height-box {
  display: inline-block;
  vertical-align: top;
  height: 23px;
  line-height: 23px;
}
.tb-padded-box {
  display: inline-block;
  vertical-align: top;
  padding: 6px;
}
.tb-padded {
  padding: 6px;
}
.tb-padded-wide {
  padding: 12px;
}
.tb-padded-small {
  padding: 3px;
}
.tb-padded-top-large {
  padding-top: 18px;
}
.tb-padded-left {
  padding-left: 6px;
}
.tb-padded-left-large {
  padding-left: 18px;
}
.tb-padded-right {
  padding-right: 6px;
}
.tb-padded-right-large {
  padding-right: 12px;
}
.tb-padded-bottom {
  padding-bottom: 6px;
}
.tb-padded-top-bottom {
  padding: 6px 0px;
}
.tb-padded-bottom-medium {
  padding-bottom: 12px;
}
.tb-lr-padded {
  padding-left: 6px;
  padding-right: 6px;
}
.tb-lr-padded-narrow {
  padding-left: 3px;
  padding-right: 3px;
}
.tb-lr-padded-wide {
  padding-left: 12px;
  padding-right: 12px;
}
.tb-cell-height-padding {
  padding-top: 7px;
  padding-bottom: 7px;
}
.tb-section {
  margin-top: 26px;
}
.tb-row {
  margin-top: 6px;
}
.tb-margin-left-wide {
  margin-left: 12px;
}
.tb-margin-left-xlarge {
  margin-left: 35px;
}
.tb-margin-top-bottom {
  margin-top: 6px;
  margin-bottom: 6px;
}
.tb-margin-top-wide {
  margin-top: 12px;
}
.tb-margin-bottom-wide {
  margin-bottom: 12px;
}
.tb-margin-right {
  margin-right: 6px;
}
.tb-margin-right-wide {
  margin-right: 12px;
}
.tb-margin-small {
  margin: 3px;
}
.tb-margin-top-small {
  margin-top: 3px;
}
.tb-margin-bottom-small {
  margin-bottom: 3px;
}
.tb-margin-top-bottom-small {
  margin-top: 3px;
  margin-bottom: 3px;
}
.tb-half-width {
  width: 50%;
}
.tb-full-width {
  width: 100%;
}
.tb-full-height {
  height: 100%;
}
.tb-border-box {
  box-sizing: border-box;
}
