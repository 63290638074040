.tb-dialog-external-users {
  width: 500px;
  margin-top: 12px;
}
.tb-dialog-external-users .tb-dialog-authentication-option {
  margin-bottom: 3px;
}
.tb-dialog-external-users .tb-dialog-authentication-option-description {
  margin-left: 21px;
  margin-bottom: 12px;
  width: 468px;
  font-size: 11px;
}
.tb-dialog-external-users .tb-select {
  margin-top: 12px;
  display: inline-block;
  width: 100%;
}
.tb-dialog-external-users .tb-dialog-external-users-prompt {
  display: inline-block;
  margin-left: 12px;
  width: 170px;
  vertical-align: top;
  color: #808080;
  font-size: 11px;
}
.tb-dialog-external-users .tb-dialog-warnings-list {
  background-color: #ffff80;
  margin-top: 12px;
  padding: 3px;
  max-height: 200px;
  overflow: auto;
}
.tb-dialog-external-users .tb-dialog-invalid-email-warning {
  margin: 3px;
}
.tb-dialog-external-users .tb-dialog-external-users-quota {
  text-align: right;
}
.tb-dialog-external-users .tb-authentication-provider-selector {
  margin-bottom: 12px;
}
