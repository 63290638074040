.tb-datasource-remote-query-agent-prompt {
  margin-bottom: 12px;
}
.tb-details-datasource-remote-query-agent-block {
  padding-top: 12px;
  padding-bottom: 0;
}
.tb-details-datasource-remote-query-agent-select-group-body {
  padding-left: 21px;
}
.tb-details-datasource-remote-query-agent-description {
  color: #999;
  line-height: 16px;
}
.tb-details-datasource-remote-query-agent-select-selector {
  display: inline-block;
  width: 350px;
}
