.tb-favorite-button {
  display: inline-block;
  vertical-align: top;
  width: var(--header-button-size, 24px);
  min-width: var(--header-button-size, 24px);
  height: var(--header-button-size, 24px);
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(img/Spaces_Caption_Favorite_Off.svg?2023_3_25_mo7mxn7zi4);
  background-size: var(--header-icon-size, 18px);
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 2px;
}
.tb-favorite-button:hover {
  outline: 0;
  background-color: var(--header-background-hover-color, #ebebeb);
  background-image: url(img/Spaces_Caption_Favorite_Off_Active.svg?2023_3_25_mo7mxn7zi4);
}
.tb-favorite-button:focus {
  outline: 0;
  border: 1px solid $focusBorderColor;
  box-shadow: 0 0 4px $focusBorderColor;
}
.tb-favorite-button.tb-selected {
  background-image: url(img/Spaces_Caption_Favorite_On.svg?2023_3_25_mo7mxn7zi4);
}
.tb-favorite-button.tb-selected:hover {
  background-image: url(img/Spaces_Caption_Favorite_On_Active.svg?2023_3_25_mo7mxn7zi4);
}
.tb-favorite-button-light {
  display: inline-block;
  vertical-align: top;
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 3px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(img/Spaces_Caption_Favorite_Off_White.svg?2023_3_25_mo7mxn7zi4);
  background-size: 18px;
  border: 1px solid transparent;
}
.tb-favorite-button-light:hover {
  outline: 0;
  background-image: url(img/Spaces_Caption_Favorite_Off_Active_White.svg?2023_3_25_mo7mxn7zi4);
}
.tb-favorite-button-light:focus {
  outline: 0;
  background-image: url(img/Spaces_Caption_Favorite_Off_Active_White.svg?2023_3_25_mo7mxn7zi4);
  border: 1px solid $focusBorderColor;
  box-shadow: 0 0 4px $focusBorderColor;
}
.tb-favorite-button-light.tb-selected {
  background-image: url(img/Spaces_Caption_Favorite_On_White.svg?2023_3_25_mo7mxn7zi4);
}
.tb-favorite-button-light.tb-selected:hover,
.tb-favorite-button-light.tb-selected:focus {
  background-image: url(img/Spaces_Caption_Favorite_On_Active_White.svg?2023_3_25_mo7mxn7zi4);
}
.tb-favorite-button-cell {
  display: inline-block;
  vertical-align: top;
  width: 37px;
  height: 37px;
  line-height: 37px;
}
.tb-favorite-button-cell .tb-favorite-button {
  position: relative;
  top: 6px;
  left: 6px;
}
.tb-favorites-dropdown img {
  display: none;
}
.tb-favorites-dropdown button {
  background-color: inherit !important;
}
.tb-favorites-dropdown span {
  padding: 0;
}
