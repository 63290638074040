.tb-workbook-publish-success-modal-with-preview {
  text-align: left;
}
.tb-workbook-publish-success-modal-with-preview .tb-link-datasource,
.tb-workbook-publish-success-modal-with-preview .tb-link-workbook {
  height: 18px;
}
.tb-workbook-publish-success-modal-with-preview .tb-datasources .tb-datasources-title {
  font-size: 14px;
  font-weight: normal;
}
.tb-workbook-publish-success-modal-with-preview .tb-connections-section {
  padding: 0;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items {
  padding: 0 18px 0 0;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-workbook-body,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources-body {
  margin-top: 12px;
  margin-bottom: 12px;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-workbook-body .tb-published-item .tb-workbook-wrapper,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources-body .tb-published-item .tb-workbook-wrapper,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-workbook-body .tb-published-item .tb-datasource-wrapper,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources-body .tb-published-item .tb-datasource-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-workbook-body .tb-published-item .tb-workbook-wrapper .tb-published-icon,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources-body .tb-published-item .tb-workbook-wrapper .tb-published-icon,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-workbook-body .tb-published-item .tb-datasource-wrapper .tb-published-icon,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources-body .tb-published-item .tb-datasource-wrapper .tb-published-icon {
  flex: 0 0 auto;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-workbook-body .tb-published-item .tb-workbook-wrapper .tb-published-name,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources-body .tb-published-item .tb-workbook-wrapper .tb-published-name,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-workbook-body .tb-published-item .tb-datasource-wrapper .tb-published-name,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources-body .tb-published-item .tb-datasource-wrapper .tb-published-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  padding-left: 2px;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-datasources {
  margin-top: 18px;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-device-preview,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-data-refresh {
  padding-left: 22px;
}
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-device-preview .tb-device-preview-text,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-data-refresh .tb-device-preview-text,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-device-preview .tb-data-refresh-text,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-data-refresh .tb-data-refresh-text,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-device-preview .tb-data-refresh-link,
.tb-workbook-publish-success-modal-with-preview .tb-published-items .tb-data-refresh .tb-data-refresh-link {
  line-height: 16px;
  font-weight: normal;
  font-size: 12px;
}
.tb-workbook-publish-success-modal-with-preview .tb-publish-details {
  background-color: #f5f5f5;
  margin-top: 18px;
  padding: 12px;
}
.tb-workbook-publish-success-modal-with-preview .tb-datasource-publish-details-popover-trigger {
  text-decoration: underline;
}
.publish-complete-dialog-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}
.publish-complete-an-ext-section {
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  padding-bottom: 15px;
  padding-top: 15px;
}
.publish-complete-an-ext-no-connection-selected {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}
.publish-complete-an-ext-no-connection-selected-text {
  font-weight: bold;
}
.publish-complete-an-ext-no-connection-warning {
  margin-bottom: 15px;
}
.publish-complete-an-ext-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.publish-complete-an-ext-dropdown {
  width: 350px;
}
.publish-complete-an-ext-dropdown-item {
  display: flex;
  flex-direction: row;
  width: 290px;
}
.publish-complete-an-ext-apply-button {
  align-self: flex-end;
}
