.tb-refresh-mode-picker {
  display: inline-block;
  margin-left: 6px;
  min-width: 150px;
  vertical-align: middle;
}
.tb-details-refresh-mode-picker {
  white-space: normal;
}
.tb-details-refresh-mode-picker-description {
  color: #999;
  line-height: 16px;
}
.tb-details-refresh-agent-picker {
  display: inline-block;
  width: 400px;
}
.tb-details-refresh-mode-block-first {
  padding-bottom: 12px;
}
.tb-details-refresh-mode-block {
  padding-top: 12px;
  padding-bottom: 12px;
}
.tb-details-refresh-mode-block-last {
  padding-top: 12px;
  padding-bottom: 0;
}
.tb-details-refresh-mode-select-group-body {
  padding-left: 21px;
}
.tb-details-refresh-mode-select-selector {
  display: inline-block;
  width: 350px;
}
