.tb-help-menu {
  width: 230px;
}
.tb-help-search {
  display: inline-block;
  vertical-align: top;
  width: 230px;
  position: relative;
  margin-bottom: 3px;
}
.tb-copyright {
  margin-top: 12px;
}
