div.tb-connection-details {
  padding-bottom: 18px;
  white-space: nowrap;
}
div.tb-connection-details .tb-connection-details-row {
  display: table-row;
}
div.tb-connection-details .tb-connection-details-label {
  display: table-cell;
}
div.tb-connection-details .tb-connection-details-value {
  display: table-cell;
  padding-left: 18px;
}
.tb-connection-details-label {
  font-weight: bold;
}
.tb-connection-credentials-username {
  padding-bottom: 12px;
  width: 100%;
}
.tb-connection-credentials-password {
  padding-bottom: 18px;
  width: 100%;
}
.tb-o-auth-sign-in {
  margin-top: 12px;
  text-align: center;
}
.tb-embedded-auth-advisory {
  text-align: left;
  margin: 18px auto;
  padding: 3px 18px;
  border: 1px solid #333;
  max-width: 70%;
}
