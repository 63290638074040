.tb-clear-all-filters {
  height: 23px;
  line-height: 23px;
  padding: 0 18px;
}
.tb-search-filter {
  height: 23px;
  line-height: 23px;
  padding: 0 18px;
}
.tb-filter-section {
  padding-top: 12px;
}
.tb-filter-section-with-border {
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid #e6e6e6;
}
.tb-filter-section-header {
  border-top: 1px solid #e6e6e6;
  padding: 9px 18px;
  font-size: 15px;
  font-weight: normal;
  color: #333;
}
.tb-filter-checkboxes {
  padding-top: 12px;
}
.tb-filter-checkboxes .tb-filter {
  padding: 0 18px 6px;
}
.tb-filter {
  padding: 3px 18px 0;
}
.tb-filter-label {
  display: block;
  line-height: 23px;
  position: relative;
}
.tb-filter-popup-menu-button-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-clear-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  line-height: 23px;
}
.tb-filter-panel-dropdown {
  height: 320px;
}
