.tb-site-membership {
  padding: 6px;
}
.tb-site-membership >.tb-picker-container {
  position: relative;
  width: 500px;
  height: 400px;
  margin-bottom: 34px;
}
.tb-site-membership .tb-dialog-description {
  width: 500px;
}
