.tb-site-authentication {
  line-height: 16px;
}
.tb-site-authentication .tb-static-checkmark {
  display: inline-block;
  vertical-align: top;
  width: 15px;
  height: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(img/checkbox_checkmark.png?2023_3_25_mo7mxn7zi4);
}
.tb-site-authentication .tb-checkbox-label {
  display: inline-block;
}
.tb-site-authentication .tb-checkbox-indent {
  padding-left: 21px;
}
.tb-site-authentication .tb-enabled-color {
  color: #15557f;
  font-weight: bold;
}
.tb-site-authentication .tb-status-indicator {
  display: inline-block;
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  vertical-align: middle;
  height: 23px;
  line-height: 21px;
  font-size: 11px;
  padding: 0 22px;
}
.tb-site-authentication .tb-outline-button {
  line-height: 23px;
  margin-right: 0;
  margin-left: 0;
}
.tb-site-authentication .tb-group-name {
  font-weight: normal;
  font-size: 15px;
  padding-bottom: 12px;
  color: #333;
}
.tb-site-authentication .tb-divider {
  border-bottom: 1px solid #888;
}
.tb-site-authentication .tb-radio-button-group {
  padding-bottom: 6px;
}
.tb-site-authentication .tb-group-number {
  margin-right: 12px;
}
.tb-site-authentication .tb-group-content {
  margin-left: 35px;
  margin-bottom: 12px;
}
.tb-site-authentication .tb-group-content .tb-group-name {
  margin-top: 12px;
}
.tb-site-authentication .tb-auth-options-list table td.tb-auth-options-list-item {
  padding-left: 5px;
}
.tb-site-authentication .tb-dark-highlight {
  font-weight: bold;
}
.tb-site-authentication .tb-disabled * {
  color: rgba(0,0,0,0.56) !important;
}
.tb-site-authentication table {
  border-spacing: 0;
}
.tb-site-authentication .tb-label-padding {
  padding-left: 123px;
}
.tb-site-authentication .tb-auth-options-right-align {
  text-align: right;
  margin: 5px 0;
}
.tb-site-authentication .tb-float-left {
  float: left;
}
.tb-site-authentication .tb-float-right {
  float: right;
}
.tb-site-authentication .tb-auth-options-label {
  display: inline-block;
  text-align: left;
  width: 123px;
  vertical-align: middle;
}
.tb-site-authentication .tb-url-holder {
  padding: 2px;
  width: 520px;
  background-color: #eee;
  border: 1px #999 solid;
  color: rgba(0,0,0,0.8);
}
.tb-site-authentication .tb-file-input {
  margin-right: 12px;
  margin-bottom: 0;
  flex-grow: 1;
}
.tb-site-authentication .tb-file-input .tb-filename-label {
  padding-right: 0;
}
.tb-site-authentication .tb-auth-options-left-margin {
  margin-left: 35px;
}
.tb-site-authentication .tb-auth-assertions table {
  border: 10px solid #eee;
  border-collapse: collapse;
  margin-bottom: 10px;
  text-align: left;
}
.tb-site-authentication .tb-auth-assertions table td {
  padding: 5px;
  width: 308.5px;
  background-color: #eee;
}
.tb-site-authentication .tb-auth-assertions table td input[type=text],
.tb-site-authentication .tb-auth-assertions table td select {
  width: 100%;
}
.tb-site-authentication .tb-auth-assertions table td.tb-auth-assertions-radio {
  width: 185.5px;
  text-align: right;
  padding-right: 12px;
}
.tb-site-authentication .tb-auth-assertions table td.tb-auth-assertions-radio .tb-radio-button {
  display: inline-block;
}
.tb-site-authentication .tb-auth-assertions table td.tb-auth-assertions-radio-label {
  width: 120px;
}
.tb-site-authentication .tb-auth-assertions table tr.tb-auth-assertion-grouping td {
  background-color: #fff;
}
.tb-site-authentication .tb-auth-table-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
}
.tb-site-authentication .tb-auth-table-header-right {
  margin-left: auto;
}
.tb-site-authentication .tb-users-link {
  padding-right: 12px;
  white-space: nowrap;
}
.tb-site-authentication .tb-form-indent {
  margin-left: 29px;
}
.tb-site-authentication .tb-auth-options-row {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 3px;
}
.tb-site-authentication .tb-auth-options-row .tb-align-right {
  margin-left: auto;
}
.tb-site-authentication .tb-auth-options-row .tb-readonly-field {
  flex-grow: 1;
}
.tb-site-authentication .tb-readonly-field {
  padding: 2px;
  border: 1px #999 solid;
  color: rgba(0,0,0,0.8);
  background-color: #eee;
}
.tb-site-authentication .tb-filename-label {
  width: 123px;
}
.tb-site-authentication .tb-filename-display {
  margin-left: 0;
}
.tb-dialog-body-template .tb-site-authentication {
  overflow-x: auto;
  overflow-y: auto;
  width: 740px;
  max-height: 345px;
  padding-right: 6px;
}
