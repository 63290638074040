.tb-react-data-grid {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
}
.tb-react-dg-hrow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 37px;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.tb-react-dg-hcols {
  display: inline-block;
  height: 37px;
  white-space: nowrap;
}
.taller-rows .tb-react-dg-hrow,
.taller-rows .tb-react-dg-hcols {
  height: 44px;
}
.tb-react-dg-hcol {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.tb-react-dg-hcell {
  height: 37px;
  line-height: 37px;
  font-size: 13px;
  font-weight: bold;
  color: #333;
}
.taller-rows .tb-react-dg-hcell {
  height: 44px;
  line-height: 44px;
}
.tb-react-dg-hcell-sortable {
  cursor: pointer;
}
.tb-react-dg-hcell-sortable:hover {
  background-color: #f5f5f5;
}
.tb-react-dg-hcell-resizable {
  border-right: 1px solid #ddd;
}
.tb-react-dg-hdivider {
  position: absolute;
  z-index: 1;
  top: 0;
  right: -6px;
  bottom: 0;
  width: 12px;
  cursor: col-resize;
}
.tb-react-dg-body {
  background-color: #fff;
}
.tb-react-dg-bcell {
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.tb-spaces-list-view .tb-react-dg-bcell {
  padding-left: 0;
  padding-right: 0;
}
.tb-spaces-list-view .tb-react-dg-hrow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 37px;
  background-color: #fff;
  border: none;
}
.tb-datarole-values-list .tb-react-dg-hrow {
  border-top: none;
}
.tb-spaces-list-view .tb-react-dg-hcol {
  border: none !important;
}
.tb-spaces-list-view .tb-react-dg-hcell-resizable {
  border: none;
}
.tb-spaces-list-view .tb-favorite-button-cell .tb-favorite-button {
  top: auto;
  left: auto;
}
.tb-spaces-list-view .tb-react-dg-bcell:focus {
  outline: none;
}
