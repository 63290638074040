.tb-scroll {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: auto;
  overflow-y: auto;
  outline: 0;
}
.tb-scroll-content-area {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.tb-scroll-content {
  position: absolute;
  left: 0;
  right: 0;
  line-height: 0;
}
