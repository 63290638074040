.tb-empty-state-button {
  display: inline-block;
  border: 1px solid #cbcbcb;
  height: 24px;
  margin-top: 29px;
  line-height: 22px;
  font-size: 12px;
  padding: 0 24px;
  cursor: default;
  background-color: none;
  color: rgba(0,0,0,0.7);
  text-decoration: none;
  outline: 0;
  white-space: nowrap;
  border-radius: 1px;
  -o-border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -khtml-border-radius: 1px;
}
.tb-empty-state-button:hover,
.tb-empty-state-button:focus {
  border-color: #666;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
  outline: 0;
}
.tb-empty-state-button:active {
  background-color: none;
  border-color: #666;
  color: #333;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  opacity: 0.8;
  outline: 0;
}
.tb-outline-button {
  display: inline-block;
  border: 1px solid #666;
  height: 21px;
  line-height: 21px;
  font-size: 12px;
  font-weight: bold;
  padding: 0 24px;
  cursor: pointer;
  background-color: transparent;
  color: #666;
  text-decoration: none;
  outline: 0;
  white-space: nowrap;
  border-radius: 1px;
  -o-border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -khtml-border-radius: 1px;
}
.tb-outline-button:hover {
  background-color: #ebebeb;
}
.tb-outline-button:focus {
  border-color: #3498db;
  -moz-box-shadow: 0 0 4px 0 #3498db;
  -webkit-box-shadow: 0 0 4px 0 #3498db;
  box-shadow: 0 0 4px 0 #3498db;
  text-decoration: none;
}
.tb-outline-button:active {
  background-color: #ccc;
  border-color: #333;
  color: #333;
}
.tb-outline-button.tb-filled-when-selected {
  background-color: transparent;
}
.tb-outline-button.tb-filled-when-selected.tb-selected {
  background-color: #fff;
}
.tb-outline-button.tb-disabled {
  cursor: default;
  background-color: transparent;
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.tb-outline-button.tb-go-button {
  font-weight: bold;
  background-color: #1a699e;
  border-color: #1a699e;
  color: #fff;
}
.tb-outline-button.tb-go-button:hover {
  background-color: #2a79af;
  border-color: #2a79af;
}
.tb-outline-button.tb-go-button:focus {
  border-color: #3498db;
  -moz-box-shadow: 0 0 4px 0 #3498db;
  -webkit-box-shadow: 0 0 4px 0 #3498db;
  box-shadow: 0 0 4px 0 #3498db;
}
.tb-outline-button.tb-go-button:active {
  background-color: #15557f;
  border-color: #15557f;
}
.tb-outline-button.tb-go-button.tb-disabled {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.tb-outline-button.tb-attention-button {
  font-weight: bold;
  background-color: #c41723;
  border-color: #c41723;
  color: #fff;
}
.tb-outline-button.tb-attention-button:hover {
  background-color: #e51f2d;
  border-color: #e51f2d;
}
.tb-outline-button.tb-attention-button:focus {
  border-color: #3498db;
  -moz-box-shadow: 0 0 4px 0 #3498db;
  -webkit-box-shadow: 0 0 4px 0 #3498db;
  box-shadow: 0 0 4px 0 #3498db;
}
.tb-outline-button.tb-attention-button:active {
  background-color: #a0131c;
  border-color: #a0131c;
}
.tb-outline-button.tb-attention-button.tb-disabled {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.tb-outline-button.tb-new-button {
  background-color: #eb8f50;
  border-color: #eb8f50;
  color: #fff;
}
.tb-outline-button.tb-new-button:hover,
.tb-outline-button.tb-new-button:focus {
  background-color: #eb8f50;
  border-color: #d16b36;
  color: #fff;
}
.tb-outline-button.tb-new-button:active {
  background-color: #d16b36;
  border-color: #d16b36;
  color: #fff;
}
.tb-outline-button.tb-new-button.tb-disabled {
  background-color: #f7d2b9;
  border-color: #f7d2b9;
  color: #fff;
}
button.tb-outline-button {
  height: 23px;
}
button {
  font-weight: normal;
}
button::-moz-focus-inner {
  outline: 0;
  border: 0;
}
.tb-outline-button.tb-dark {
  background-color: #fafafa;
}
.tb-outline-button.tb-dark:active {
  background-color: #e6e6e6;
}
.tb-outline-button.tb-dark.tb-disabled {
  border-color: #dadada;
  color: #dadada;
}
.tb-small-outline-button {
  display: inline-block;
  border: 1px solid #b2b2b2;
  width: 21px;
  height: 21px;
  line-height: 21px;
  cursor: pointer;
  background-color: #fff;
}
.tb-small-outline-button:hover,
.tb-small-outline-button:focus {
  border-color: #666;
}
.tb-small-outline-button:active {
  background-color: #f5f5f5;
  border-color: #666;
}
.tb-small-outline-button.tb-disabled {
  cursor: default;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=25)';
  filter: alpha(opacity=25);
  opacity: 0.25;
}
.tb-icon-button {
  display: inline-block;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.tb-icon-button.tb-small {
  width: 19px;
  height: 19px;
}
.tb-filter-panel-toggle-button {
  display: inline-block;
  width: 29px;
  height: 21px;
  border: 1px solid #b2b2b2;
  background: url(img/filter_toggle_normal.svg?2023_3_25_mo7mxn7zi4);
  cursor: pointer;
}
.tb-filter-panel-toggle-button.tb-selected {
  background: url(img/filter_toggle_selected.svg?2023_3_25_mo7mxn7zi4);
}
.tb-filter-panel-toggle-button:hover {
  border: 1px solid #333;
}
.tb-toggle-button {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 21px;
  border: 1px solid #b2b2b2;
}
.tb-toggle-button-handle {
  width: 29px;
  cursor: pointer;
}
.tb-toggle-button-handle:hover {
  top: -1px;
  bottom: -1px;
  border: 1px solid #333;
  z-index: 1;
}
.tb-grid-mode-handle-icon {
  background: url(img/view_toggle_grid_normal.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-grid-mode-handle-icon.tb-selected {
  background: url(img/view_toggle_grid_selected.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-list-mode-handle-icon {
  background: url(img/view_toggle_list_normal.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-list-mode-handle-icon.tb-selected {
  background: url(img/view_toggle_list_selected.svg?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-text-button {
  border: none;
  background-color: transparent;
  font: inherit;
  display: inline-block;
  height: 23px;
  line-height: 23px;
  padding: 0 6px;
  cursor: pointer;
  color: #666;
  text-decoration: none;
}
.tb-text-button:hover {
  background-color: #e6e6e6;
  color: #333;
  text-decoration: none;
}
.tb-text-button.tb-disabled,
.tb-text-button.tb-disabled:hover,
.tb-text-button.tb-disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #e1e1e1;
}
.tb-text-button.tb-disabled.tb-dark,
.tb-text-button.tb-disabled:hover.tb-dark,
.tb-text-button.tb-disabled:focus.tb-dark {
  color: #dadada;
}
.tb-subplace-tab-button {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  cursor: pointer;
  padding-left: 6px;
  margin-right: 20px;
}
.tb-subplace-tab-button >.tb-label {
  display: inline-block;
  vertical-align: baseline;
  height: 31px;
  line-height: 31px;
  font-weight: fongWeight;
  font-size: 15px;
  color: #666;
  border-bottom: 3px solid transparent;
  margin-right: 6px;
}
.tb-subplace-tab-button >.tb-count {
  display: inline-block;
  vertical-align: baseline;
  height: 31px;
  line-height: 31px;
  font-size: 12px;
  border-bottom: 3px solid transparent;
  padding-left: 6px;
  color: rgba(0,0,0,0.56);
  padding-right: 6px;
}
.tb-subplace-tab-button:hover,
.tb-subplace-tab-button:focus {
  text-decoration: none;
}
.tb-subplace-tab-button:hover >.tb-label,
.tb-subplace-tab-button:focus >.tb-label {
  color: #000;
}
.tb-subplace-tab-button.tb-selected >.tb-label,
.tb-subplace-tab-button.tb-selected:hover >.tb-label {
  color: #333;
  font-weight: bold;
  border-bottom: 3px solid #2a5775;
}
.tb-subplace-tab-button.tb-selected >.tb-count,
.tb-subplace-tab-button.tb-selected:hover >.tb-count {
  color: #333;
}
.tb-tabs-banner .tb-subplace-tab-button >.tb-label {
  height: 41px;
  line-height: 41px;
  font-size: 18px;
}
.tb-tabs-banner .tb-subplace-tab-button >.tb-count {
  height: 41px;
  line-height: 41px;
}
.tb-orange-button {
  display: inline-block;
  line-height: 34px;
  margin-top: 29px;
  padding: 6px 18px;
  background-color: #eb8f50;
  text-align: center;
  font-family: inherit;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  border: 0;
  outline: 0;
  border-radius: 1px;
  -o-border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -khtml-border-radius: 1px;
  transition: background-color 150ms ease-in;
  -o-transition: background-color 150ms ease-in;
  -moz-transition: background-color 150ms ease-in;
  -webkit-transition: background-color 150ms ease-in;
  -khtml-transition: background-color 150ms ease-in;
}
.tb-orange-button:hover,
.tb-orange-button:focus {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #d16b36;
}
.tb-orange-button.tb-button-busy {
  padding: 6px 70px;
  position: relative;
  color: #fff;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=35)';
  filter: alpha(opacity=35);
  opacity: 0.35;
  transition: none;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -khtml-transition: none;
  cursor: default;
}
.tb-orange-button.tb-button-busy:hover,
.tb-orange-button.tb-button-busy:focus {
  background-color: #eb8f50;
}
.tb-orange-button.tb-disabled {
  background-color: #f7d2b9;
  border-color: #f7d2b9;
  color: #fff;
}
.tb-orange-button.tb-disabled:hover,
.tb-orange-button.tb-disabled:focus {
  background-color: #f7d2b9;
}
.tb-login-form-container .tb-orange-button {
  background-color: #355c80;
  margin-top: 15px;
}
.tb-login-form-container .tb-orange-button:hover,
.tb-login-form-container .tb-orange-button:focus {
  background-color: #222636;
}
.tb-login-form-container .tb-orange-button.tb-button-busy:hover,
.tb-login-form-container .tb-orange-button.tb-button-busy:focus {
  background-color: #222636;
}
.tb-login-form-container .tb-orange-button.tb-disabled {
  background-color: #355c80;
  border-color: #355c80;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=35)';
  filter: alpha(opacity=35);
  opacity: 0.35;
}
.tb-login-form-container .tb-orange-button.tb-disabled:hover,
.tb-login-form-container .tb-orange-button.tb-disabled:focus {
  border-color: #355c80;
}
.tb-header-menu-button:focus {
  outline: 1px solid #3498db;
  -moz-box-shadow: 0 0 4px #3498db;
  -webkit-box-shadow: 0 0 4px #3498db;
  box-shadow: 0 0 4px #3498db;
}
