.tb-text-box-holder {
  position: relative;
  height: 23px;
}
.tb-text-box-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #b2b2b2;
  background-color: #fff;
  color: rgba(0,0,0,0.8);
  padding: 1px 7px;
}
.tb-text-box-border:hover {
  border: 1px solid #666;
  color: #333;
}
.tb-text-box-border.tb-clearable {
  padding-right: 23px;
}
.tb-text-box-border.tb-disabled,
.tb-text-box-border.tb-disabled:hover {
  border-color: #e1e1e1;
  color: rgba(0,0,0,0.35);
}
.tb-placeholder::-webkit-input-placeholder {
  font-style: italic;
}
.tb-placeholder::-moz-placeholder {
  font-style: italic;
}
.tb-placeholder:-moz-placeholder {
  font-style: italic;
}
.tb-placeholder:-ms-input-placeholder {
  font-style: italic;
}
.tb-text-box-input {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 19px;
  line-height: 19px;
  margin-left: -6px;
  margin-right: -6px;
  padding-left: 6px;
  padding-right: 6px;
  color: inherit;
  background-color: #fff;
  vertical-align: top;
  font-size: 12px;
  font-family: inherit;
}
.tb-text-box-input::-webkit-input-placeholder {
  font-style: italic;
}
.tb-text-box-input::-moz-placeholder {
  font-style: italic;
}
.tb-text-box-input:-moz-placeholder {
  font-style: italic;
}
.tb-text-box-input:-ms-input-placeholder {
  font-style: italic;
}
.tb-text-box-input::-ms-clear {
  display: none;
}
.tb-clear-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 23px;
  line-height: 23px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: -1px;
  color: rgba(0,0,0,0.56);
}
.tb-clear-button:hover {
  color: #333;
}
.tb-clear-button-icon {
  background-image: url(img/widget_small_close.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center;
}
.tb-clear-button-icon:hover {
  background-image: url(img/widget_small_close_active.svg?2023_3_25_mo7mxn7zi4);
}
label.placeholder {
  font-style: italic !important;
}
.tb-multiline-text-holder {
  padding: 7px;
}
.tb-react-multiline-text-holder {
  padding: 3px;
}
.tb-multiline-text {
  width: 100%;
  height: 100px;
  resize: vertical;
  margin: -7px;
  padding: 6px;
  border: 1px solid #b2b2b2;
  outline: 0;
  font-size: 12px;
  font-family: inherit;
  color: rgba(0,0,0,0.8);
  background-color: #fff;
}
.tb-multiline-text:hover,
.tb-multiline-text:focus {
  border: 1px solid #666;
  color: #333;
}
