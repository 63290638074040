.tb-datasource-publish-success-modal .tb-datasource-publish-details {
  background-color: #f5f5f5;
  padding: 18px 35px;
  text-align: left;
}
.tb-datasource-publish-success-modal .tb-datasource-publish-details-popover-trigger {
  text-decoration: underline;
}
.tb-datasource-publish-success-modal .tb-datasource-publish-details-popover-info-icon {
  display: inline-block;
  margin-left: -3px;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-datasource-publish-success-modal .tb-datasource-publish-details-popover-info-icon:hover {
  background-image: url(img/info_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-datasource-publish-success-modal .tb-link-datasource {
  height: 18px;
}
.tb-datasource-publish-success-modal .tb-datasource-publish-name {
  padding-bottom: 18px;
}
.tb-datasource-publish-success-modal .tb-dialog-actions {
  margin-top: 18px;
  text-align: right;
  padding-bottom: 12px;
}
