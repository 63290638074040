.tb-action-options {
  width: 500px;
}
.tb-action-options .tb-padded-bottom-medium {
  padding-bottom: 12px;
}
.tb-action-options .tb-padded-cell {
  padding-top: 12px;
  padding-left: 18px;
}
.tb-action-options .tb-wide-button {
  width: 100%;
}
.tb-action-options .tb-button-desc {
  padding: 0;
}
