.tb-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  display: none;
  -webkit-overflow-scrolling: touch;
  font-family: Benton Sans, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: rgba(0,0,0,0.8);
  line-height: 1.5em;
}
