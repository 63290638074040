.tb-add-groups-list {
  position: relative;
  height: 200px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: alpha(opacity=100);
  opacity: 1;
  border: 1px solid #b2b2b2;
  margin-top: 12px;
}
.tb-add-groups-list.tb-initial-state {
  height: 0px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  opacity: 0;
}
.tb-add-groups-list .tb-scroll {
  margin: 3px;
}
.tb-add-groups-dialog-message {
  font-size: 11px;
  height: 16px;
}
.tb-add-groups-dialog-message,
.tb-add-groups-dialog-timeout-message {
  margin-top: 6px;
}
.tb-error-color {
  color: #c41723;
}
.tb-import-group-site-role-picker {
  position: relative;
  height: 20px;
  margin-top: 10px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: alpha(opacity=100);
  opacity: 1;
}
.tb-import-group-site-role-picker.tb-initial-state {
  height: 0px;
  margin-top: 0px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  opacity: 0;
}
.tb-create-group-site-role-picker {
  position: relative;
  margin-top: 10px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: alpha(opacity=100);
  opacity: 1;
}
.tb-create-group-site-role-picker.tb-initial-state {
  height: 0px;
  margin-top: 0px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  opacity: 0;
}
.tb-import-group-dialog-actions {
  margin-top: 18px;
  white-space: nowrap;
}
