.tb-learn-more {
  font-style: normal;
}
a {
  color: #1a699e;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
}
a:hover {
  color: #2a79af;
  text-decoration: underline;
}
a:focus {
  color: #15557f;
  text-decoration: underline;
}
a.tb-disabled {
  color: rgba(0,0,0,0.35);
  text-decoration: none;
  pointer-events: none;
}
.tb-data-grid-cell-text a.tb-disabled,
.tb-details-value a.tb-disabled {
  color: rgba(0,0,0,0.8);
}
