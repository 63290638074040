.tb-device-preview-button-container {
  float: right;
  margin-top: 7px;
  margin-right: 24px;
}
.tb-device-picker-bar {
  font-weight: normal;
}
.tb-device-picker-bar .tab-preview-tools-container {
  display: flex;
}
.tb-device-picker-bar .tab-preview-tools-container .tab-left-spacer {
  flex: 1;
}
.tb-device-picker-bar .tab-preview-tools-container .tab-device-bar {
  flex: 0 0 auto;
  display: flex;
}
.tb-device-picker-bar .tab-preview-tools-container .tab-right-spacer {
  flex: 1;
  display: flex;
}
.tb-device-picker-bar .tab-preview-tools-container .tab-right-spacer .tab-collapsible-margin {
  flex: 0 1 70px;
}
.tb-device-picker-bar .tab-device-icon {
  padding-left: 12px;
  padding-right: 12px;
}
.tb-device-viz-preview-fill {
  background: #282828;
  top: 65px;
}
.tb-viz.tb-device-viz-preview {
  background-color: #fff;
  overflow: hidden;
/* margin and transform are here because of the way some browsers calculate margin: auto leaves remnants behind on the screen 
     Far as I know, only happens in Safari out of our list of browsers */
  margin-left: 50%;
  transform: translateX(-50%);
}
.tb-viz.tb-device-viz-preview iframe {
  background: #fff;
}
.tb-viz-preview-viewer.tb-vizviewer {
  border: none;
}
.tb-viz-preview-viewer.tb-vizviewer.tb-viz-with-breadcrumb-bar {
  bottom: 0;
}
.tb-viz-preview-viewer .tb-preview-title-box {
  line-height: 18px;
}
