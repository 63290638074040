.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-prompt {
  margin-bottom: 12px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-mode,
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-hourly,
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-daily,
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-details {
  display: inline-block;
  vertical-align: top;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-mode {
  padding-right: 6px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-mode-option {
  margin: 3px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-right-small-space {
  margin-right: 6px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-right-large-space {
  padding-right: 70px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-details-row {
  padding-bottom: 6px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-row-caption-cell {
  display: inline-block;
  line-height: 23px;
  margin-right: 6px;
  text-align: right;
  vertical-align: top;
  width: 70px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-row-selector {
  display: inline-block;
  vertical-align: top;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-refresh-type {
  margin-top: 12px;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-refresh-type-prompt {
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-remote-refresh-custom-schedule-dialog .tb-custom-schedule-editor-refresh-type-option {
  margin: 3px;
  display: block;
}
