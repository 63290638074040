.tb-filtering-list-box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1px;
}
.tb-filtering-list-box .tb-data-grid-cell,
.tb-filtering-list-box .tb-filtering-list-box-row,
.tb-filtering-list-box .tb-data-grid-cell-text,
.tb-filtering-list-box .tb-data-grid-row-background {
  height: 27px;
  line-height: 27px;
}
.tb-filtering-list-box-textbox {
  position: relative;
}
.tb-filtering-list-box-textbox .tb-text-box-border {
  padding-left: 21px;
}
.tb-filtering-list-box-textbox .tb-search-button {
  left: 4px;
}
.tb-list-box {
  position: absolute;
  top: 31px;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}
.tb-list-box strong {
  font-style: normal;
  text-decoration: underline;
}
.tb-list-box .tb-scroll {
  margin: 0 4px 4px 4px;
}
.tb-filtering-list-box-row {
  height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-dialog-filtering-list-box-wrapper {
  position: relative;
  height: 300px;
}
.tb-dialog-filtering-list-box-wrapper .tb-filtering-list-box {
  padding: 0;
}
.tb-dialog-filtering-list-box-wrapper .tb-filtering-list-box-textbox {
  padding: 3px 0;
}
.tb-dialog-filtering-list-box-wrapper .tb-filtering-list-box-textbox .tb-search-button {
  left: 1px;
}
.tb-dialog-filtering-list-box-wrapper .tb-list-box {
  border: 1px solid #b2b2b2;
  padding: 0;
  position: absolute;
  top: 29px;
  right: 0;
  bottom: 0;
  left: 0;
}
.tb-dialog-filtering-list-box-wrapper .tb-list-box .tb-scroll {
  margin: 3px;
}
