.tb-content-item-popover {
  position: absolute;
  top: 76.8px;
  width: 300px;
  line-height: 16px;
  font-size: 12px;
}
.tb-content-item-popover.tb-workbook,
.tb-content-item-popover.tb-view {
  top: 57.6px;
}
.tb-content-item-popover.tb-workbook.tb-popover-right,
.tb-content-item-popover.tb-view.tb-popover-right {
  left: -2px;
}
.tb-content-item-popover.tb-view.tb-popover-left {
  left: 18px;
}
.tb-content-item-popover.tb-workbook.tb-popover-left {
  left: 15px;
}
.tb-content-item-popover-title {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  max-height: 7.5em;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 1.5em;
}
.tb-content-item-popover-sheets {
  float: right;
  padding-left: 6px;
  padding-top: 2px;
}
.tb-content-item-popover-description {
  max-height: 7.5em;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 12px;
  line-height: 1.5em;
}
.tb-content-item-popover-details {
  margin-top: 12px;
  white-space: nowrap;
}
.tb-content-item-popover-details-row {
  padding-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-content-item-popover-label {
  float: left;
  padding-right: 12px;
  white-space: nowrap;
  color: #999;
}
.tb-content-item-popover-value {
  color: #999;
}
.tb-content-item-popover-value-multiline {
  overflow: hidden;
  white-space: normal;
  max-height: 4.5em;
  line-height: 1.5em;
}
.tb-content-item-metadata {
  margin-top: 12px;
}
