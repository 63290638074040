.tb-create-schedule-form .tb-form-label {
  display: inline-block;
  vertical-align: top;
  min-width: 115px;
  line-height: 23px;
  text-align: right;
  padding-right: 12px;
}
.tb-create-schedule-form .tb-form-label.tb-form-label-wide {
  min-width: 140px;
}
.tb-create-schedule-form .tb-form-label.tb-form-label-tall {
  vertical-align: top;
  line-height: normal;
  padding-top: 1px;
}
.tb-create-schedule-form .tb-form-value {
  display: inline-block;
}
.tb-create-schedule-form-subtitle {
  margin-bottom: 24px;
}
.tb-create-schedule-form-subtitle-name {
  font-weight: normal;
  font-size: 14px;
}
.tb-create-schedule-form-subtitle-divider {
  padding: 0 9px;
  color: #999;
}
.tb-create-schedule-form-priority .tb-text-box-holder {
  display: inline-block;
  width: 60px;
}
.tb-create-schedule-form-serialization {
  white-space: normal;
}
.tb-create-schedule-form-serialization label {
  display: block;
}
.tb-settings-group.tb-create-schedule-form-frequency {
  min-width: 636px;
  min-height: 150px;
  padding-bottom: 0;
}
.tb-create-schedule-form-type {
  display: inline-block;
  vertical-align: top;
  width: 140px;
}
.tb-create-schedule-form-type label {
  display: block;
}
.tb-create-schedule-form-details {
  display: inline-block;
}
.tb-create-schedule-form-details .tb-settings-group {
  padding-top: 0;
  padding-bottom: 6px;
}
.tb-create-schedule-form-details .tb-settings-group td {
  padding: 0 0 6px;
}
.tb-create-schedule-form-details .tb-settings-group td > span {
  vertical-align: top;
}
.tb-create-schedule-form-details .tb-form-label-cell {
  text-align: right;
}
.tb-create-schedule-form-details .tb-form-label {
  display: inline-block;
  vertical-align: top;
  min-width: 0;
  padding-right: 0;
}
.tb-create-schedule-form-details .tb-popup-menu {
  font-size: 11px;
}
.tb-create-schedule-form-hourly {
  border-collapse: collapse;
}
.tb-create-schedule-form-weekly-days,
.tb-create-schedule-form-weekly-start-time {
  display: inline-block;
  vertical-align: top;
}
.tb-create-schedule-form-weekly-days {
  margin-right: 6px;
}
.tb-schedule-settings-label {
  display: block;
  font-weight: bold;
  margin: 18px 0;
}
.tb-schedule-settings-label:first-child {
  margin-top: 0;
}
.tb-create-schedule-task-type .tb-popup-menu {
  width: 180px;
}
.tb-details .tb-create-schedule-form-weekly-days .tb-checkbox-group {
  height: 20px;
}
.tb-details .tb-create-schedule-form-weekly-days .tb-checkbox-group span {
  vertical-align: top;
}
