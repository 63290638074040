.tb-icon-spinner {
  display: inline-block;
  background-image: url(img/busy.gif?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
}
.tb-icon-spinner-large,
.tb-icon-spinner-large-alternate {
  display: inline-block;
  background-image: url(img/busy_large.gif?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
.tb-icon-spinner-large-alternate {
  background-image: url(img/busy_alternate_large.gif?2023_3_25_mo7mxn7zi4);
}
.tb-loading-indicator {
  background-image: url(img/busy_large.gif?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px;
}
.tb-orange-button.tb-button-busy .tb-icon-spinner-large {
  position: absolute;
  top: auto;
  right: 19px;
  bottom: auto;
  left: auto;
}
.tb-login-form-container .tb-button-login:not(.tb-button-busy) .tb-icon-sign-in-arrow {
  display: inline-block;
  vertical-align: middle;
  background-image: url(img/SignIn_Arrow_White.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-left: 12px;
}
.tb-icon-alert {
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  display: inline-block;
  background-image: url(img/alert_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-alert:hover {
  background-image: url(img/alert_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-alert-list-header {
  background-image: url(img/alert_list_header_normal.svg?2023_3_25_mo7mxn7zi4);
  display: inline-block;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
}
.tb-icon-server-alert-list-suspended {
  background-image: url(img/server_alert_list_suspended.svg?2023_3_25_mo7mxn7zi4);
  display: inline-block;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
}
.tb-icon-server-alert-list-suspended:hover {
  background-image: url(img/server_alert_list_suspended_active.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-server-alert-list-suspended-active {
  background-image: url(img/server_alert_list_suspended_active.svg?2023_3_25_mo7mxn7zi4);
  display: inline-block;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
}
.tb-icon-dialogs-status-alert {
  background-image: url(img/dialogs_status_alert.svg?2023_3_25_mo7mxn7zi4);
  display: inline-block;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
}
.tb-icon-dialogs-status-alert:hover {
  background-image: url(img/dialogs_status_alert_active.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-dialogs-status-alert-active {
  background-image: url(img/dialogs_status_alert_active.svg?2023_3_25_mo7mxn7zi4);
  display: inline-block;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
}
.tb-popup-menu:focus .tb-icon-help-menu {
  background-image: url(img/help_header_icon.svg?2023_3_25_mo7mxn7zi4);
}
.tb-action-icon {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/actions_normal.svg?2023_3_25_mo7mxn7zi4);
  background-size: 19px 19px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 37px;
  height: 37px;
  cursor: pointer;
}
.tb-action-icon:hover {
  background-image: url(img/actions_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-popup-menu:focus .tb-action-icon {
  background-image: url(img/actions_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-action-icon-svg {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/thumbnail_ellipsis_normal.svg?2023_3_25_mo7mxn7zi4);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.tb-action-icon-svg:hover {
  background-image: url(img/thumbnail_ellipsis_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-drop-down-icon {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/down_arrow_normal.svg?2023_3_25_mo7mxn7zi4);
  background-position: center center;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.tb-drop-down-icon:hover {
  background-image: url(img/column_dropdown_arrow_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-help {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
  background-size: 19px 19px;
}
.tb-icon-help:hover {
  background-image: url(img/info_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-info {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-info:hover {
  background-image: url(img/info_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-info-no-hover {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-permissions-info {
  display: table-cell;
  vertical-align: bottom;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-project-permissions-info {
  display: inline-block;
  vertical-align: top;
  vertical-align: text-bottom;
  margin-left: 3px;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-image: url(img/info_normal.svg?2023_3_25_mo7mxn7zi4);
  background-size: 19px 19px;
}
.tb-icon-project-permissions-info:hover {
  background-image: url(img/info_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-checkmark {
  background-image: url(img/check_14px-small.svg?2023_3_25_mo7mxn7zi4);
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  display: inline-block;
}
.tb-icon-delete-small-normal {
  background-image: url(img/delete.png?2023_3_25_mo7mxn7zi4);
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  display: inline-block;
}
.tb-place-action-icon {
  box-sizing: border-box;
  height: 31px;
  width: 31px;
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 6px;
}
.tb-place-action-icon:hover {
  padding: 0;
}
.tb-new-workbook-icon {
  background-image: url(img/new_workbook_small_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-new-workbook-icon:hover,
.tb-new-workbook-icon:focus {
  background-image: url(img/new_workbook_small_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-edit-workbook-icon {
  background-image: url(img/edit_small_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-edit-workbook-icon:hover,
.tb-edit-workbook-icon:focus {
  background-image: url(img/edit_small_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-download-icon {
  background-image: url(img/download_small_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-download-icon:hover,
.tb-download-icon:focus {
  background-image: url(img/download_small_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-rename-icon {
  background-image: url(img/rename_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-rename-icon:hover,
.tb-rename-icon:focus {
  background-image: url(img/rename_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-run-now-icon {
  background-image: url(img/run_now_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-run-now-icon:hover,
.tb-run-now-icon:focus {
  background-image: url(img/run_now_small_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-refresh-icon {
  background-image: url(img/refresh_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-refresh-icon:hover,
.tb-refresh-icon:focus {
  background-image: url(img/refresh_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-rename-editable-text-icon {
  padding-left: 31px;
  background-repeat: no-repeat;
  background-position: 6px center;
  background-image: url(img/rename_normal.svg?2023_3_25_mo7mxn7zi4);
  background-size: 17px 17px;
}
.tb-rename-editable-text-icon:hover,
.tb-rename-editable-text-icon:focus {
  background-image: url(img/rename_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-create-new-icon {
  padding-right: 18px;
  background-repeat: no-repeat;
  background-position: -3px center;
  background-image: url(img/create_new.png?2023_3_25_mo7mxn7zi4);
}
.tb-link-datasource,
.tb-link-flow,
.tb-link-group,
.tb-link-project,
.tb-link-schedule,
.tb-link-site,
.tb-link-user,
.tb-link-view,
.tb-link-workbook {
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 23px;
}
.tb-grid-icon-datasource,
.tb-grid-icon-flow,
.tb-grid-icon-group,
.tb-grid-icon-project,
.tb-grid-icon-published-connection,
.tb-grid-icon-schedule,
.tb-grid-icon-site,
.tb-grid-icon-user,
.tb-grid-icon-view,
.tb-grid-icon-view-attached-pdf,
.tb-grid-icon-view-attached-all,
.tb-grid-icon-workbook,
.tb-grid-icon-workbook-attached-pdf,
.tb-grid-icon-workbook-attached-all,
.tb-grid-icon-alert-suspended {
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left center;
  width: 23px;
  height: 37px;
}
.tb-grid-icon-datasource.tb-grid-icon-small-row,
.tb-grid-icon-flow.tb-grid-icon-small-row,
.tb-grid-icon-group.tb-grid-icon-small-row,
.tb-grid-icon-project.tb-grid-icon-small-row,
.tb-grid-icon-published-connection.tb-grid-icon-small-row,
.tb-grid-icon-schedule.tb-grid-icon-small-row,
.tb-grid-icon-site.tb-grid-icon-small-row,
.tb-grid-icon-user.tb-grid-icon-small-row,
.tb-grid-icon-view.tb-grid-icon-small-row,
.tb-grid-icon-view-attached-pdf.tb-grid-icon-small-row,
.tb-grid-icon-view-attached-all.tb-grid-icon-small-row,
.tb-grid-icon-workbook.tb-grid-icon-small-row,
.tb-grid-icon-workbook-attached-pdf.tb-grid-icon-small-row,
.tb-grid-icon-workbook-attached-all.tb-grid-icon-small-row,
.tb-grid-icon-alert-suspended.tb-grid-icon-small-row {
  height: 26px;
}
.tb-link-datasource,
.tb-grid-icon-datasource {
  background-image: url(img/data_source_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-link-flow,
.tb-grid-icon-flow {
  background-image: url(img/flow_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-link-group,
.tb-grid-icon-group {
  background-size: 17px 17px;
  background-image: url(img/group_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-link-project,
.tb-grid-icon-project {
  background-image: url(img/project_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-published-connection {
  background-image: url(img/server_connection_18px-large.svg?2023_3_25_mo7mxn7zi4);
}
.tb-link-schedule,
.tb-grid-icon-schedule {
  background-size: 17px 17px;
  background-image: url(img/schedule_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-schedule-stylish {
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left center;
  width: 23px;
  height: 44px;
  background-size: 17px 17px;
  background-image: url(img/schedule_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-link-site,
.tb-grid-icon-site {
  background-size: 17px 17px;
  background-image: url(img/site_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-site-stylish {
  display: inline-block;
  vertical-align: top;
  background-size: 20px 20px;
  background-image: url(img/site_content_type_small.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: left center;
  width: 26px;
  height: 44px;
}
.tb-link-user,
.tb-grid-icon-user {
  background-size: 17px 17px;
  background-image: url(img/user_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-link-view,
.tb-grid-icon-view {
  background-image: url(img/view_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-alert-suspended {
  background-image: url(img/server_alert_list_suspended.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-alert-suspended:hover {
  background-image: url(img/server_alert_list_suspended_active.svg?2023_3_25_mo7mxn7zi4);
}
.tb-link-workbook,
.tb-grid-icon-workbook {
  background-image: url(img/workbook_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-view-attached-pdf {
  background-image: url(img/content_type_attached_pdf_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-view-attached-all {
  background-image: url(img/view_content_type_attached_all_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-workbook-attached-pdf {
  background-image: url(img/content_type_attached_pdf_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-grid-icon-workbook-attached-all {
  background-image: url(img/workbook_content_type_attached_all_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-datasource-icon,
.tb-icon-datasource-embedded,
.tb-icon-datasource-published,
.tb-icon-datasource-connection-file,
.tb-icon-datasource-connection-external,
.tb-icon-datasource-connection-tableau {
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left center;
  width: 23px;
  height: 37px;
}
.tb-icon-datasource-embedded {
  background-size: 17px 17px;
  background-image: url(img/data_source_embedded_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-datasource-published {
  background-size: 17px 17px;
  background-image: url(img/data_source_content_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-datasource-connection-file {
  background-size: 17px 17px;
  background-image: url(img/file_connection_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-datasource-connection-external {
  background-size: 17px 17px;
  background-image: url(img/external_server_connection_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-datasource-connection-tableau {
  background-size: 17px 17px;
  background-image: url(img/tableau_server_connection_type_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-icon-datasource-extract {
  background-image: url(img/data_extract_small.png?2023_3_25_mo7mxn7zi4);
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left center;
  height: 17px;
  width: 17px;
}
.tb-project-large {
  height: 140px;
  width: 140px;
  background-image: url(img/project_content_type_large.png?2023_3_25_mo7mxn7zi4);
}
.tb-icon-process-status {
  display: inline-block;
  width: 21px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
}
.tb-icon-process-status-active {
  background-image: url(img/node-status-active.png?2023_3_25_mo7mxn7zi4);
}
.tb-icon-process-status-passive {
  background-image: url(img/node-status-passive.png?2023_3_25_mo7mxn7zi4);
}
.tb-icon-process-status-unlicensed {
  background-image: url(img/node-status-unlicensed.png?2023_3_25_mo7mxn7zi4);
}
.tb-icon-process-status-external {
  background-image: url(img/node-status-external.png?2023_3_25_mo7mxn7zi4);
}
.tb-icon-process-status-busy {
  background-image: url(img/node-status-busy-animated.gif?2023_3_25_mo7mxn7zi4);
}
.tb-status-legend-item > .tb-icon-process-status-busy {
  background-image: url(img/node-status-busy.png?2023_3_25_mo7mxn7zi4);
}
.tb-icon-process-status-down {
  background-image: url(img/node-status-down.png?2023_3_25_mo7mxn7zi4);
}
.tb-icon-process-status-statusnotavailable {
  background-image: url(img/node-status-unavailable.png?2023_3_25_mo7mxn7zi4);
}
.tb-row-with-icon-on-right {
  display: inline-flex;
  padding-right: 29px;
  position: relative;
}
.tb-row-icon-on-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 23px;
  height: 23px;
  background-position: center center;
}
.tb-row-icon-on-right-react {
  position: absolute;
  top: -5px;
  right: 0;
  bottom: 0;
  left: auto;
  width: 23px;
  height: 23px;
  background-position: center center;
}
.tb-header-row-icon {
  height: 35px;
  background-position: center center;
}
.tb-header-row-icon-centered {
  background-position: center center;
  padding: 0px 10px 0px 30px;
  boxSizing: border-box;
  height: 24px;
}
.tb-dropdown-label-icon-on-right {
  margin-left: 3px;
  padding: 0px 2px 2px 0px;
}
.tb-white-icon-permissions-locked {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/locked_white_normal.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center center;
  height: 19px;
  width: 19px;
}
.tb-white-icon-permissions-unlocked {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/unlocked_white_normal.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center center;
  height: 19px;
  width: 19px;
}
.tb-white-icon-permissions-mixed {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/locked_mixed_white_normal.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center center;
  height: 19px;
  width: 19px;
}
.tb-gray-icon-permissions-locked {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/locked_gray_normal.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center center;
  height: 19px;
  width: 19px;
}
.tb-gray-icon-permissions-unlocked {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/unlocked_gray_normal.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center center;
  height: 19px;
  width: 19px;
}
.tb-gray-icon-permissions-mixed {
  display: inline-block;
  vertical-align: top;
  background-image: url(img/locked_mixed_gray_normal.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center center;
  height: 19px;
  width: 19px;
}
.tb-section-open-icon {
  display: inline-block;
  vertical-align: top;
  width: 19px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url(img/panel_show_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-section-open-icon:hover {
  background-image: url(img/panel_show_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-section-close-icon {
  display: inline-block;
  vertical-align: top;
  width: 19px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url(img/panel_hide_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-section-close-icon:hover {
  background-image: url(img/panel_hide_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-dialogs-workbook {
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  background-repeat: no-repeat;
  background-image: url(img/dialogs_workbook.svg?2023_3_25_mo7mxn7zi4);
  padding-left: 3px;
}
.tb-dialogs-warning {
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  background-repeat: no-repeat;
  background-image: url(img/dialogs_warning.svg?2023_3_25_mo7mxn7zi4);
  padding-left: 3px;
}
.tb-data-pane-workbook-icon,
.tb-data-pane-datasource-icon,
.tb-data-pane-datasource-extract-icon,
.tb-data-pane-datasource-cube-icon,
.tb-prep-flow-icon {
  height: 17px;
  width: 17px;
  background-repeat: no-repeat;
  background-image: url(img/published_type_workbook.png?2023_3_25_mo7mxn7zi4);
  padding-left: 3px;
}
.tb-data-pane-datasource-icon {
  background-image: url(img/published_type_datasource.png?2023_3_25_mo7mxn7zi4);
}
.tb-data-pane-datasource-extract-icon {
  background-image: url(img/published_type_datasource_extract.png?2023_3_25_mo7mxn7zi4);
}
.tb-data-pane-datasource-cube-icon {
  background-image: url(img/published_type_datasource_cube.png?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-icon {
  background-image: url(img/published_type_flow.png?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-overview-history-status-icon,
.tb-prep-flow-overview-history-status-pending-icon,
.tb-prep-flow-overview-history-status-inprogress-icon,
.tb-prep-flow-overview-history-status-success-icon,
.tb-prep-flow-overview-history-status-failed-icon,
.tb-prep-flow-overview-history-status-cancelled-icon,
.tb-prep-flow-overview-run-now-icon {
  display: inline-block;
  vertical-align: top;
  background-size: 17px 17px;
  background-repeat: no-repeat;
  background-position: left center;
  width: 23px;
  height: 37px;
}
.tb-prep-flow-overview-history-status-pending-icon {
  background-image: url(img/flow_run_pending.svg?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-overview-history-status-inprogress-icon {
  background-image: url(img/flow_run_in_progress.svg?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-overview-history-status-success-icon {
  background-image: url(img/flow_run_succeeded.svg?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-overview-history-status-failed-icon {
  background-image: url(img/flow_run_error.svg?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-overview-history-status-cancelled-icon {
  background-image: url(img/flow_run_cancelled.svg?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-overview-run-now-icon {
  background-image: url(img/run_now_small.svg?2023_3_25_mo7mxn7zi4);
}
.tb-prep-flow-overview-run-now-icon:hover {
  background-image: url(img/run_now_small_hover.svg?2023_3_25_mo7mxn7zi4);
}
.tb-published-connection-policies-applied {
  display: inline-block;
  vertical-align: top;
  background-size: 17px 17px;
  background-image: url(img/shield_14px_small.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: left center;
  width: 23px;
  height: 37px;
}
.tb-published-connection-policies-applied-metadata {
  background-repeat: no-repeat;
  background-image: url(img/shield_14px_small.svg?2023_3_25_mo7mxn7zi4);
  background-position: left center;
}
