.tb-file-input {
  display: flex;
  margin-bottom: 12px;
}
.tb-file-input input[type="file"] {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 0;
}
.tb-file-input .tb-filename-label {
  vertical-align: top;
  padding-top: 5px;
  padding-right: 18px;
}
.tb-file-input .tb-filename-display {
  width: 200px;
  height: 19px;
  margin-left: 6px;
  margin-right: 12px;
  color: rgba(0,0,0,0.8);
  border: 1px solid #b2b2b2;
  font-size: 11px;
  font-family: inherit;
  flex: 1;
  -webkit-flex: 1;
}
