.tb-edit-connection-form {
  display: table;
  width: 100%;
}
.tb-edit-connection-form .tb-settings-group {
  display: table-row;
}
.tb-edit-connection-form .tb-form-label {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  padding-right: 12px;
  padding-bottom: 12px;
}
.tb-edit-connection-form .tb-form-label.tb-form-label-tall {
  vertical-align: top;
  padding-top: 1px;
}
.tb-edit-connection-form .tb-form-value {
  display: table-cell;
  padding-bottom: 12px;
}
.tb-edit-connection-prompt {
  margin-bottom: 18px;
}
.tb-edit-connection-sub-header {
  font-weight: bold;
  margin-bottom: 12px;
}
.tb-check-connection-result {
  margin-top: 6px;
}
.tb-edit-salesforce-connection-form .tb-edit-oauth-connection-option-label,
.tb-edit-oauth-connection-form .tb-edit-oauth-connection-option-label {
  margin-bottom: 6px;
  display: block;
}
.tb-edit-salesforce-connection-form .tb-invisible,
.tb-edit-oauth-connection-form .tb-invisible {
  visibility: hidden;
}
.tb-edit-salesforce-connection-form .tb-edit-oauth-connection-option-contents,
.tb-edit-oauth-connection-form .tb-edit-oauth-connection-option-contents {
  margin-left: 21px;
  margin-bottom: 12px;
  display: inline-block;
}
.tb-edit-salesforce-connection-form .tb-text-box-holder,
.tb-edit-oauth-connection-form .tb-text-box-holder,
.tb-edit-salesforce-connection-form .tb-select,
.tb-edit-oauth-connection-form .tb-select {
  width: 300px;
  margin-bottom: 6px;
}
.tb-edit-salesforce-connection-form .tb-select,
.tb-edit-oauth-connection-form .tb-select {
  display: inline-block;
}
.tb-edit-salesforce-connection-form .tb-edit-oauth-connection-option-description,
.tb-edit-oauth-connection-form .tb-edit-oauth-connection-option-description {
  color: rgba(0,0,0,0.56);
}
