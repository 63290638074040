.tb-tags-input {
  position: relative;
  margin: 1px;
  background-color: #fff;
}
.tb-tags-input .tb-tags-input-field {
  border: solid 1px #b2b2b2;
  font-size: 12px;
}
.tb-tags-input .tb-tag-container-wrapper {
  max-width: 100%;
  display: inline-block;
}
.tb-tags-input .tb-tag-container {
  position: relative;
  background-color: #c0e3ff;
  border: 1px solid #bac2cc;
  color: #243241;
  margin: 3px;
  padding: 1px 4px 2px;
  border-radius: 2px;
  -o-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
  cursor: default;
}
.tb-tags-input .tb-tag-container.tb-partial-tag {
  background-color: #e0e9f1;
}
.tb-tags-input .tb-tag-container.tb-selected {
  background-color: #8fbc8f;
}
.tb-tags-input .tb-tag-delete {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 10px;
  margin-right: 3px;
}
.tb-tags-input .tb-tag-display-wrapper {
  padding-right: 13px;
  vertical-align: middle;
}
.tb-tags-input .tb-tag-display {
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-tags-input .tb-tag-content {
  margin-left: 3px;
}
.tb-tags-input .tb-tag-input-wrapper {
  margin: 3px;
  padding: 0 3px;
  position: relative;
}
.tb-tags-input .tb-tag-input {
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
}
.tb-tags-input .tb-tag-input:focus {
  outline: 0;
}
