.tb-data-grid {
  cursor: default;
  background-color: #fff;
}
th {
  text-align: left;
}
.tb-static-grid-table {
  border-spacing: 0;
  text-align: left;
  background-color: #fff;
  cursor: default;
}
.tb-static-grid-table th {
  font-weight: normal;
  background-color: #fafafa;
  color: #333;
}
.tb-static-grid-table th.tb-data-grid-headers-line-multiline {
  padding-top: 6px;
}
.tb-static-grid-table th.tb-data-grid-headers-line-multiline .tb-data-grid-header-text {
  padding-bottom: 6px;
}
.tb-static-grid-table td {
  padding: 0;
}
.tb-data-grid-headers-line {
  height: 37px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
}
.tb-data-grid-headers {
  display: inline-block;
  vertical-align: top;
}
.tb-data-grid-header {
  display: inline-block;
  vertical-align: top;
  height: 37px;
  position: relative;
  white-space: nowrap;
  color: #333;
}
.tb-data-grid-header-cell.tb-clickable {
  cursor: pointer;
}
.tb-data-grid-header-cell.tb-clickable:hover {
  text-decoration: underline;
}
.tb-data-grid-header-content {
  height: 37px;
  line-height: 37px;
}
.tb-data-grid-header-content-numeric {
  text-align: right;
}
.tb-data-grid-header-content-icon {
  text-align: center;
}
.tb-data-grid-header-text {
  font-size: 13px;
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: -5px center;
}
.tb-data-grid-header-text.tb-sort-asc {
  background-image: url(img/sort_arrow_ascending.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-header-text.tb-sort-desc {
  background-image: url(img/sort_arrow_descending.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-licenses-header-text-multiline {
  white-space: normal;
  font-size: 13px;
  font-weight: bold;
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: -5px center;
}
.tb-data-grid-licenses-header-text-multiline.tb-sort-asc {
  background-image: url(img/sort_arrow_ascending.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-licenses-header-text-multiline.tb-sort-desc {
  background-image: url(img/sort_arrow_descending.svg?2023_3_25_mo7mxn7zi4);
}
.tb-data-grid-body {
  top: 0;
}
.tb-data-grid-body.tb-data-grid-has-headers {
  top: 39px;
}
.tb-data-grid-body-columns {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.tb-data-grid-body-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.tb-data-grid-row-background {
  height: 37px;
  border-bottom: 1px solid #fff;
}
.tb-show-lines .tb-data-grid-row-background,
.tb-data-grid-separator-row {
  border-bottom: 1px solid #e6e6e6;
}
.tb-data-grid-body-column {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.tb-data-grid-cell {
  height: 37px;
  border-bottom: 1px solid transparent;
}
.tb-data-grid-cell-text {
  color: rgba(0,0,0,0.8);
  height: 37px;
  line-height: 37px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tb-data-grid-cell-text-numeric {
  text-align: right;
}
.tb-data-grid-cell-text-date {
  text-align: left;
}
.tb-lr-padded-wide-right {
  padding-right: 12px;
}
.tb-data-grid-cell-text-icon {
  text-align: center;
  min-width: 15px;
}
.tb-quality-indicator-cell {
  display: inline-block;
  vertical-align: top;
  width: 43px;
  height: 37px;
  padding-top: 3px;
  text-align: center;
}
.tb-data-grid-icon {
  background-position: center;
  vertical-align: top;
  min-width: 15px;
  height: 37px !important;
}
.tb-data-grid-header-icon {
  background-position: center;
  vertical-align: top;
  min-width: 15px;
  height: 37px !important;
}
.tb-data-grid-column-divider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 8px;
  cursor: col-resize;
}
.tb-data-grid-current-row {
  background-color: #f5f5f5;
}
.tb-data-grid-hover-row {
  background-color: #f5f5f5;
}
.tb-data-grid-active-row {
  background-color: #e6e6e6;
}
.tb-data-grid-selected-row {
  background-color: #d4e1ed;
}
.tb-highlight-current-row .tb-data-grid-hover-row {
  background-color: #f5f5f5;
}
.tb-highlight-current-row .tb-data-grid-current-row {
  background-color: #d4e1ed;
}
.tb-highlight-current-row .tb-data-grid-active-row {
  background-color: #e6e6e6;
}
.tb-highlight-current-row .tb-data-grid-selected-row {
  background-color: #d4e1ed;
}
.tb-hide-current-row .tb-data-grid-current-row {
  background-color: transparent;
}
.tb-hide-current-row .tb-data-grid-hover-row {
  background-color: #f5f5f5;
}
.tb-hide-current-row .tb-data-grid-active-row {
  background-color: #e6e6e6;
}
.tb-hide-current-row .tb-data-grid-selected-row {
  background-color: #d4e1ed;
}
.tb-data-grid-no-matching-results {
  font-size: 15px;
  margin-top: 18px;
  padding: 6px;
  line-height: 23px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-data-grid-empty {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}
.tb-data-grid-empty .tb-data-grid-empty-title {
  font-weight: normal;
  font-size: 18px;
  color: #4e4e4e;
  padding-top: 48px;
  padding-bottom: 32px;
}
.tb-data-grid-empty .tb-data-grid-empty-body {
  font-size: 13px;
  padding-bottom: 18px;
}
.tb-data-grid-empty .tb-data-grid-empty-body-paragraph {
  text-align: left;
  padding-bottom: 12px;
}
.tb-data-grid-empty .tb-data-grid-empty-help-link {
  font-size: 13px;
}
.tb-site-membership-grid-empty {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.tb-site-membership-grid-empty .tb-data-grid-empty-title {
  font-weight: normal;
  font-size: 18px;
  color: #4e4e4e;
  padding-top: 48px;
  padding-bottom: 32px;
}
.tb-site-membership-grid-empty .tb-data-grid-empty-body {
  font-size: 13px;
  padding-bottom: 18px;
}
.tb-site-membership-grid-empty .tb-data-grid-empty-body-paragraph {
  text-align: left;
  padding-bottom: 12px;
}
.tb-site-membership-grid-empty .tb-data-grid-empty-help-link {
  font-size: 13px;
}
.tb-data-grid-margin-left .tb-data-grid-headers {
  margin-left: 12px;
}
.tb-data-grid-margin-left .tb-data-grid-body-columns {
  margin-left: 12px;
}
.tb-data-grid-dialog-tooltip-link {
  font-family: BentonSans-BookItalic, Arial, Helvetica, sans-serif;
}
.tb-data-grid-dialog-tooltip-link:hover {
  text-decoration: underline !important;
}
#interactive-tooltip-Dialog-Body-Id {
  padding: 0px !important;
}
