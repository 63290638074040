@font-face {
  font-family: BentonSans-Book;
  font-weight: normal;
  font-style: normal;
  src: url(img/BentonSans-Book.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-Book.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: BentonSans-Book_Italic;
  font-weight: normal;
  font-style: normal;
  src: url(img/BentonSans-Book_Italic.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-Book_Italic.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: BentonSans-Medium;
  font-weight: normal;
  font-style: normal;
  src: url(img/BentonSans-Medium.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-Medium.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: BentonSans-SemiDemi;
  font-weight: normal;
  font-style: normal;
  src: url(img/BentonSans-SemiDemi.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-SemiDemi.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: BentonSans-SemiDemi_Italic;
  font-weight: normal;
  font-style: normal;
  src: url(img/BentonSans-SemiDemi_Italic.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-SemiDemi_Italic.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: Benton Sans;
  font-weight: normal;
  font-style: normal;
  src: url(img/BentonSans-Book.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-Book.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: Benton Sans;
  font-weight: normal;
  font-style: italic;
  src: url(img/BentonSans-Book_Italic.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-Book_Italic.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: Benton Sans;
  font-weight: 500;
  font-style: normal;
  src: url(img/BentonSans-SemiDemi.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-SemiDemi.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: Benton Sans;
  font-weight: bold;
  font-style: normal;
  src: url(img/BentonSans-Medium.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-Medium.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: Benton Sans Low-DPI;
  font-weight: normal;
  font-style: normal;
  src: url(img/BentonSans-SemiDemi.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-SemiDemi.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
@font-face {
  font-family: Benton Sans Low-DPI;
  font-weight: normal;
  font-style: italic;
  src: url(img/BentonSans-SemiDemi_Italic.woff2?2023_3_25_mo7mxn7zi4) format('woff2'), url(img/BentonSans-SemiDemi_Italic.woff?2023_3_25_mo7mxn7zi4) format('woff');
}
