.tb-toaster {
  position: absolute;
  width: 100%;
  height: 0;
  top: 50px;
}
.tb-toast {
  position: relative;
  padding: 6px;
  line-height: 16px;
  min-height: 16px;
  margin: 0 auto 6px auto;
  border-radius: 1px;
  -o-border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -khtml-border-radius: 1px;
  width: 400px;
}
.tb-toast >.tb-clear-button {
  width: 28px;
  height: 28px;
  line-height: 28px;
  margin-top: 0;
}
.tb-toast-text {
  width: 377px;
  word-wrap: break-word;
  display: inline-block;
}
.tb-toast-info,
.tb-toast-infonofadeout {
  background-color: #ace5c8;
  color: #003f33;
}
.tb-toast-error {
  background-color: #ffdbe0;
  color: #9b2f3c;
}
.tb-toast-external {
  width: 768px;
  padding: 0;
  background-color: #fff;
  line-height: normal;
}
