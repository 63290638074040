.tb-user-place .tb-user-info {
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  white-space: normal;
  color: #333;
}
.tb-user-place .tb-user-info-item-label {
  padding-right: 6px;
  color: #999;
}
.tb-user-place .tb-user-info-item-domain {
  color: #999;
}
.tb-user-place .tb-user-info-item-divider {
  padding: 0 9px;
  color: #999;
}
.tb-buy-licenses-button {
  position: absolute;
  right: 18px;
  bottom: 8px;
}
