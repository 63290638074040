.tb-add-local-user {
  width: 500px;
}
.tb-add-local-user .tb-error-text {
  color: #c41723;
}
.tb-add-local-user .tb-form-table {
  margin-bottom: 18px;
  width: 100%;
}
.tb-add-local-user .tb-form-table .tb-text-input-label-cell {
  width: 120px;
}
.tb-add-local-user .tb-form-table .tb-react-text-input-label-cell {
  width: 25%;
}
.tb-add-local-user .tb-form-table .tb-react-site-role-popover {
  width: 8%;
}
.tb-add-local-user .tb-form-table .tb-immutable-text-cell {
  padding-bottom: 6px;
  height: 23px;
}
.tb-add-local-user .tb-form-table .tb-newUserFlow-table .tb-available-cell {
  height: 18px;
}
.tb-add-local-user .tb-form-table .tb-newUserFlow-table .tb-available-text {
  color: #1a699e;
}
.tb-add-local-user .tb-form-table .tb-otherSiteFlow-table .tb-warning-cell {
  height: 23px;
  padding-bottom: 6px;
}
.tb-add-local-user .tb-form-table .tb-otherSiteFlow-table .tb-proceed-button {
  float: right;
}
.tb-add-local-user .tb-form-table .tb-otherSiteFlow-table .tb-warning-text {
  color: #c41723;
  margin-right: 12px;
  float: right;
}
.tb-add-local-user .tb-form-table .tb-notAvailableFlow-table .tb-error-cell {
  height: 23px;
  padding-bottom: 6px;
}
