.tb-search-panel {
  position: relative;
}
.tb-search-box {
  height: 23px;
}
.tb-search-box .tb-text-box-border {
  padding-left: 21px;
}
.tb-search-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 21px;
  background-color: transparent;
  border: none;
  text-indent: -9999px;
  background-image: url(img/magnifying_glass_small_normal.svg?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.tb-search-button:hover {
  background-image: url(img/magnifying_glass_small_hover.svg?2023_3_25_mo7mxn7zi4);
}
