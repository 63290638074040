.tb-action-panel-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  height: 37px;
  line-height: 37px;
  border-top: 1px solid #e6e6e6;
  padding-left: 18px;
  background-color: #f5f5f5;
  color: #fff;
}
.tb-action-panel-title-text {
  margin-right: 37px;
  font-size: 15px;
}
.tb-action-panel-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 37px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.tb-action-panel-controls {
  position: absolute;
  top: 39px;
  right: 0;
  bottom: 0;
  left: 0;
}
.tb-action-panel {
  position: absolute;
  top: 35px;
  right: 0;
  bottom: 0;
  left: auto;
}
.tb-action-panel-placeholder-msg {
  position: absolute;
  top: 35px;
  right: 0;
  bottom: auto;
  left: 0;
  text-align: center;
  font-size: 15px;
}
