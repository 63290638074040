.tb-site-roles-picker .tb-select-site-role-menu {
  margin: 7px 6px;
}
.tb-site-roles-picker .tb-admin-checkbox {
  height: 17px;
  margin-left: 3px;
  bottom: 0;
  position: absolute;
}
.tb-site-roles-picker .tb-admin-checkbox .tb-label {
  margin-left: 6px;
  cursor: pointer;
}
.tb-site-roles-picker .tb-data-grid-headers-line {
  border-top: none;
  background-color: #fff;
}
.tb-site-roles-picker .tb-grid-container {
  top: 52px;
  bottom: 29px;
  border: solid #b2b2b2 1px;
}
.tb-site-roles-picker .tb-scroll {
  margin: 3px;
}
.tb-site-roles-picker .tb-checkbox-cell {
  padding-top: 11px;
}
.tb-site-roles-picker .tb-data-grid-cell,
.tb-site-roles-picker .tb-data-grid-cell-text,
.tb-site-roles-picker .tb-data-grid-row-background {
  height: 37px;
  line-height: 37px;
  border-bottom: none;
}
.tb-site-roles-picker .tb-search-panel {
  position: absolute;
  left: 167px;
  right: 0;
}
.tb-membership-search {
  width: 100%;
}
.tb-membership-search input {
  width: 100%;
}
.tb-picker-container {
  position: relative;
  height: 200px;
  margin-bottom: 34px;
}
.tb-select-role-dropdown-container {
  display: inline-block;
  width: 242px;
}
.tb-site-role-column-header {
  display: inline-block;
  width: 254px;
}
.tb-site-role-permissions-popover {
  position: absolute;
  top: -41px;
  left: 10px;
}
.tb-site-role-permissions-popover-tall {
  position: absolute;
  top: -35px;
  left: 10px;
}
.tb-data-grid-no-matching-results a {
  font-size: 11px;
}
.tb-site-role-permissions-table {
  border-collapse: collapse;
  border-spacing: 0;
}
.tb-site-role-permissions-table td,
.tb-site-role-permissions-table th {
  padding: 6px;
}
.tb-site-role-permissions-table .tb-site-role-permissions-table-divider {
  border-top: 1px solid #e1e1e1;
}
.tb-site-role-permissions-table .tb-license-role-header {
  font-weight: bold;
}
.tb-site-role-permissions-table .tb-license-role-header > td {
  padding-bottom: 0px;
  padding-left: 0px;
  padding-top: 11px;
}
.tb-site-role-permissions-table .tb-license-role-header + tr > td {
  padding-top: 3px;
}
.tb-site-membership-filters {
  position: relative;
  height: 22px;
  padding-bottom: 12px;
}
.tb-site-membership-select {
  display: inline-block;
  position: relative;
  padding-right: 12px;
}
.tb-site-membership-select .tb-popup-menu {
  width: 155px;
}
.tb-admin-site-role-permissions-container td,
.tb-admin-site-role-permissions-container th {
  padding: 6px;
}
