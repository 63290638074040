.tb-dialog-create-task-schedule .tb-dialog-create-task-label {
  margin-bottom: 6px;
}
.tb-dialog-create-task-schedule .tb-sss-pane {
  width: 500px;
}
.tb-dialog-create-task-type {
  margin-top: 12px;
}
.tb-dialog-create-task-type .tb-dialog-create-task-label {
  margin-top: 3px;
}
.tb-dialog-create-task-type .tb-dialog-create-task-type-option {
  margin: 3px;
  display: block;
}
.tb-dialog-create-task-type .tb-dialog-create-task-type-option input {
  margin: 0 6px 0 0;
}
.tb-dialog-create-task-type .tb-dialog-create-task-type-option .tb-dialog-create-task-type-label {
  display: inline-block;
  vertical-align: top;
}
.tb-create-extract-refresh-mode-selector {
  border-bottom: 1px solid #e6e6e6;
}
.tb-create-extract-refresh-mode-selector-button {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  cursor: pointer;
  margin-right: 18px;
}
.tb-create-extract-refresh-mode-selector-button >.tb-label {
  display: inline-block;
  vertical-align: baseline;
  height: 27px;
  line-height: 27px;
  font-size: 15px;
  font-weight: normal;
  color: #666;
  border-bottom: 3px solid transparent;
}
.tb-create-extract-refresh-mode-selector-button:hover,
.tb-create-extract-refresh-mode-selector-button:focus {
  text-decoration: none;
}
.tb-create-extract-refresh-mode-selector-button:hover >.tb-label,
.tb-create-extract-refresh-mode-selector-button:focus >.tb-label {
  color: #333;
}
.tb-create-extract-refresh-mode-selector-button.tb-selected >.tb-label,
.tb-create-extract-refresh-mode-selector-button.tb-selected:hover >.tb-label {
  color: #333;
  font-weight: bold;
  border-bottom: 3px solid #2a5775;
}
