.tb-error-box-message {
  font-size: 13px;
  line-height: 16px;
}
.tb-error-no-site .tb-error-box {
  margin-top: 0;
}
.tb-error-box-message-first {
  font-size: 18px;
  color: #666;
  margin: 16px;
}
.tb-error-box-message-second {
  font-size: 14px;
  color: #666;
  lineHeight: 21px;
  margin-bottom: 28px;
}
