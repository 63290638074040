.tb-action-bar {
  height: 23px;
  display: flex;
  display: -webkit-flex;
  background-color: #fff;
  padding: 7px 12px 7px 18px;
}
.tb-action-bar-left {
  display: flex;
  display: -webkit-flex;
  flex-basis: 100%;
  -webkit-flex-basis: 100%;
  white-space: nowrap;
}
.tb-action-bar-center {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  flex-basis: 100%;
  -webkit-flex-basis: 100%;
  white-space: nowrap;
}
.tb-action-bar-right {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  flex-basis: 100%;
  -webkit-flex-basis: 100%;
  white-space: nowrap;
}
.tb-select-action-menu-container {
  display: inline-block;
  width: 250px;
}
.tb-action-bar-divider {
  display: inline-block;
  vertical-align: top;
  margin-left: 6px;
  margin-right: 6px;
  width: 1px;
  height: 23px;
  background-color: #e6e6e6;
}
.tb-filter-panel {
  position: absolute;
  top: 37px;
  right: auto;
  bottom: 0;
  left: calc(100% - 240px);
  width: 240px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  overflow: auto;
}
.tb-items-panel {
  position: absolute;
  top: 37px;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
}
.tb-items-panel.tb-with-filter-panel {
  right: 240px;
  width: calc(100% - 240px);
}
.tb-content-subplace-selector-label {
  margin-right: 6px;
}
.tb-content-subplace-selector .tb-popup-menu-button {
  width: 144px;
}
