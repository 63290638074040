.tb-refresh-properties-button {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-refresh-properties-prompt {
  margin-bottom: 12px;
}
.tb-details-refresh-properties-block-first {
  padding-bottom: 12px;
}
.tb-details-refresh-properties-block-last {
  padding-top: 12px;
  padding-bottom: 0;
}
.tb-details-refresh-property-select-group-title {
  position: relative;
}
.tb-details-refresh-property-select-group-title-element {
  display: inline-block;
}
.tb-details-refresh-property-select-group-body {
  padding-left: 21px;
}
.tb-details-refresh-property-description {
  color: #999;
  line-height: 16px;
}
.tb-details-refresh-property-warn {
  color: #c41723;
  line-height: 16px;
}
.tb-details-refresh-property-select-selector,
.tb-details-refresh-property-select-selector-narrow {
  display: inline-block;
  width: 350px;
}
.tb-details-refresh-property-select-selector-narrow {
  width: 200px;
}
