.tb-tooltip {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #b2b2b2;
  background-color: #f5f5f5;
  padding: 6px;
  white-space: nowrap;
  z-index: 1;
}
