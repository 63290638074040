.tb-login,
.tb-error-box {
  transition: opacity 0.5s ease-in 1s;
  -o-transition: opacity 0.5s ease-in 1s;
  -moz-transition: opacity 0.5s ease-in 1s;
  -webkit-transition: opacity 0.5s ease-in 1s;
  -khtml-transition: opacity 0.5s ease-in 1s;
  position: absolute;
  top: 15%;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  min-width: 500px;
}
.tb-login.tb-signing-in {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: alpha(opacity=100);
  opacity: 1;
  display: block !important;
}
.tb-login.tb-signing-in.ng-hide {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  opacity: 0;
}
.tb-login-form-container {
  display: inline-block;
  margin: 0 35px;
}
.tb-login-title {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 18px;
  color: #333;
}
.tb-login-logo {
  margin-bottom: 60px;
  display: inline-block;
  width: 90%;
}
.tb-login-text-box {
  display: block;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  vertical-align: top;
}
.tb-login-text-box .tb-text-box-border {
  border: 1px solid #cbcbcb;
  border-radius: 1px;
  -o-border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -khtml-border-radius: 1px;
}
.tb-login-text-box .tb-text-box-border:hover {
  border: 1px solid #333;
}
.tb-login-text-box .tb-text-box-holder {
  height: 34px;
}
.tb-login-text-box .tb-text-box-input {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}
.tb-login-text-box .tb-text-box-input:hover:not(:focus) {
  background: #fafafa !important;
}
.tb-login-text-box .tb-border-with-focus {
  border: 1px solid #3498db !important;
  -moz-box-shadow: 0 0 4px 0 #3498db;
  -webkit-box-shadow: 0 0 4px 0 #3498db;
  box-shadow: 0 0 4px 0 #3498db;
}
.tb-login-label {
  text-align: left;
  font-size: 12px;
  display: block;
  color: #4f4f4f;
  margin: 0 0 4px 4px;
}
@supports (-webkit-overflow-scrolling: touch) {
  .tb-login-text-box .tb-text-box-input {
    font-size: 16px;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .tb-login-site-picker input {
    font-size: 16px !important;
  }
}
@media only screen and (max-device-width: 640px) {
  .tb-login-site-picker-container {
    min-width: 0 !important;
    width: auto !important;
  }
  .tb-login {
    width: auto !important;
  }
}
.tb-password-reset-msg {
  margin-top: 20px;
}
.tb-login-msg-container {
  margin-top: 9px;
}
.tb-login-msg {
  display: inline-block;
  max-width: 550px;
  margin: 0 0 6px;
  text-align: center;
  font-weight: bold;
}
.tb-password-reset-success {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5em;
}
.tb-restart-password-reset {
  margin-top: 20px;
}
.tb-password-reset-success-header {
  margin-bottom: 60px;
  display: inline-block;
  font-size: 28px;
  font-weight: normal;
}
.tb-logout-msg {
  display: inline-block;
  max-width: 500px;
  margin: 0 0 6px;
  padding: 18px 18px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  border-radius: 1px;
  -o-border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -khtml-border-radius: 1px;
}
.tb-login-error {
  color: #a0131c;
}
.tb-login-success {
  color: #1a699e;
  border: 1px solid #1a699e;
}
.tb-login-windows-credentials,
.tb-login-forgot-password {
  text-align: center;
  padding-top: 12px;
}
.tb-login-site-picker-container {
  min-width: 500px;
}
.tb-login-site-picker-container .tb-login-text-box {
  width: 90%;
  max-width: 450px;
  position: relative;
}
.tb-login-site-picker-container .tb-login-text-box:hover .tb-search-button-large {
  background-image: url(img/magnifying_glass_large_hover.png?2023_3_25_mo7mxn7zi4);
}
.tb-login-site-picker {
  width: 90%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border: 1px solid #b2b2b2;
  text-align: left;
  margin-top: 6px;
  height: 50%;
  border-radius: 2px;
  -o-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
}
.tb-login-site-picker .tb-data-grid-cell,
.tb-login-site-picker .tb-data-grid-row-background,
.tb-login-site-picker .tb-data-grid-cell-text,
.tb-login-site-picker .tb-data-grid-no-matching-results {
  height: 34px;
  line-height: 34px;
  font-size: 13px;
}
.tb-login-site-picker input:first-child {
  height: 30px;
}
.tb-sign-in-note-without-url {
  font-family: "BentonSans-Medium";
  color: rgba(0,0,0,0.56);
}
.tb-sign-in-note-with-url {
  font-family: "BentonSans-Medium";
}
.tb-search-button-large {
  position: absolute;
  top: 8px;
  right: 12px;
  bottom: 0;
  left: auto;
  width: 19px;
  height: 19px;
  background-image: url(img/magnifying_glass_large_normal.png?2023_3_25_mo7mxn7zi4);
  background-repeat: no-repeat;
  background-position: center;
}
.tb-button-login {
  min-width: 224px;
  border: 1px solid transparent;
}
.tb-button-login:focus {
  border: 1px solid #3498db;
  -moz-box-shadow: 0 0 4px 0 #3498db;
  -webkit-box-shadow: 0 0 4px 0 #3498db;
  box-shadow: 0 0 4px 0 #3498db;
}
.tb-button-init {
  min-width: 264px;
}
.tb-login-notification-img {
  display: inline-block;
  width: 400px;
  margin: 0 35px;
  vertical-align: top;
}
.tb-login-mobile {
  min-width: 100%;
}
.tb-login-form-container-mobile {
  margin: 0px;
}
.tb-app-banner {
  height: 70px;
  background-color: #fafafa;
  min-width: 300px;
  overflow: hidden;
}
.tb-app-banner-close {
  width: 12px;
  height: 12px;
  margin: 29px 15px 29px 15px;
  float: left;
}
.tb-banner-logo {
  width: 48px;
  height: 48px;
  float: left;
  border-radius: 8px;
  -o-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -khtml-border-radius: 8px;
  border: 1px solid #e1e1e1;
  -moz-box-shadow: 0;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  box-sizing: border-box;
  margin: 11px 0px 11px 0px;
}
.tb-app-banner-text-area {
  float: left;
  font-size: 13px;
  font-weight: normal;
  margin: 13px 0px 13px 0px;
  color: #666;
  line-height: 1.35;
  text-decoration: none;
}
.tb-app-banner-dismiss-button {
  position: absolute;
  right: 20px;
  top: 25px;
}
.tb-app-banner-link-text {
  color: #1a699e;
  font-size: 15px;
  display: block;
}
.tb-app-banner-link-area {
  padding: 4.5px 13px 4.5px 13px;
  float: left;
}
.tb-accessible-site-picker .tb-text-box-input::-webkit-input-placeholder {
  color: #707070;
}
.tb-accessible-site-picker .tb-text-box-input::-moz-placeholder {
  color: #707070;
}
.tb-accessible-site-picker .tb-text-box-input:-moz-placeholder {
  color: #707070;
}
.tb-accessible-site-picker .tb-text-box-input:-ms-input-placeholder {
  color: #707070;
}
.tb-accessible-site-picker .tb-text-box-input::-webkit-input-placeholder {
  font-style: normal;
}
.tb-accessible-site-picker .tb-text-box-input::-moz-placeholder {
  font-style: normal;
}
.tb-accessible-site-picker .tb-text-box-input:-moz-placeholder {
  font-style: normal;
}
.tb-accessible-site-picker .tb-text-box-input:-ms-input-placeholder {
  font-style: normal;
}
