.tb-radio-button {
  position: absolute;
  cursor: pointer;
  width: 15px;
  height: 15px;
  outline: 0;
  color: rgba(0,0,0,0.8);
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url(img/radio_button_empty_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-radio-button:hover,
.tb-radio-button:focus {
  background-image: url(img/radio_button_empty_hover.svg?2023_3_25_mo7mxn7zi4);
  color: #333;
}
.tb-radio-button.tb-disabled {
  background-image: url(img/radio_button_empty_disabled.svg?2023_3_25_mo7mxn7zi4);
  cursor: default;
  color: rgba(0,0,0,0.35);
}
.tb-radio-button.tb-selected {
  background-image: url(img/radio_button_selected_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-radio-button.tb-selected:hover,
.tb-radio-button.tb-selected:focus {
  background-image: url(img/radio_button_selected_hover.svg?2023_3_25_mo7mxn7zi4);
  color: #333;
}
.tb-radio-button.tb-selected.tb-disabled {
  background-image: url(img/radio_button_selected_disabled.svg?2023_3_25_mo7mxn7zi4);
  cursor: default;
  color: rgba(0,0,0,0.35);
}
.tb-radio-button.tb-mixed {
  background-image: url(img/radio_button_mixed_normal.svg?2023_3_25_mo7mxn7zi4);
}
.tb-radio-button.tb-mixed:hover,
.tb-radio-button.tb-mixed:focus {
  background-image: url(img/radio_button_mixed_hover.svg?2023_3_25_mo7mxn7zi4);
  color: #333;
}
.tb-radio-button.tb-mixed.tb-disabled {
  background-image: url(img/radio_button_mixed_disabled.svg?2023_3_25_mo7mxn7zi4);
  cursor: default;
  color: rgba(0,0,0,0.35);
}
.tb-radio-button-label {
  margin-left: 21px;
  line-height: 15px;
  cursor: pointer;
}
.tb-radio-button-label.tb-disabled {
  cursor: default;
}
.tb-radio-button-label .tb-inline-text-box {
  display: inline-block;
  height: 23px;
  margin-right: 3px;
  vertical-align: top;
}
.tb-radio-button-group {
  position: relative;
}
.tb-radio-button-group.tb-with-inline-text-box >.tb-radio-button {
  height: 23px;
}
.tb-radio-button-group.tb-with-inline-text-box >.tb-radio-button-label {
  height: 23px;
  line-height: 23px;
}
