.tb-add-ad-users-dialog {
  width: 500px;
}
.tb-add-ad-users-dialog .tb-multiline-text {
  height: 50px;
}
.tb-add-ad-users-dialog .tb-picker-container {
  margin-top: 18px;
  height: 300px;
  margin-bottom: 34px;
}
.tb-available-quota {
  height: 16px;
  text-align: right;
}
.tb-no-quota-available-message {
  margin-top: 12px;
}
