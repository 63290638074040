#main-content:focus {
  border: #3498db 1px solid;
  box-sizing: border-box;
}
.tb-vizviewer {
  background-color: #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
.tb-vizviewer.tb-viz-with-info-bar {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 1px;
  left: 0;
}
.tb-vizviewer.tb-viz-with-breadcrumb-bar {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 1px;
  left: 0;
}
