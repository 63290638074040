.tb-server-settings-icon- {
  display: inline-block;
  width: 10px;
  height: 10px;
}
.tb-server-settings-icon-pending {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(img/busy.gif?2023_3_25_mo7mxn7zi4) no-repeat;
}
.tb-server-settings-icon-complete {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #90ee90;
}
.tb-passwords-first-row > td {
  padding-top: 26px;
}
.tb-passwords-last-row > td {
  padding-bottom: 26px;
}
.tb-frequency-label {
  vertical-align: top;
  padding-right: 35px;
}
.tb-server-settings-sign-in-note-count {
  font-size: 9px;
  display: block;
}
