.tb-connection-auth-connected {
  margin: 0 auto 18px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}
.tb-connection-auth {
  position: absolute;
  top: 10%;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}
.tb-connection-auth .tb-login-logo {
  margin-bottom: 48px;
}
.tb-connection-auth .tb-login-text-box {
  width: 270px;
}
.tb-connection-auth .tb-login-text-box .tb-text-box-border {
  border-radius: 0;
  padding-left: 1px;
  padding-right: 1px;
  border: none;
  border-bottom: 1px solid #b2b2b2;
}
.tb-connection-auth .tb-login-text-box .tb-text-box-border:hover {
  border: none;
  border-bottom: 1px solid #666;
}
.tb-connection-auth .tb-login-msg {
  padding: 18px 18px;
}
.tb-connection-auth-prompt {
  margin: 0 auto 12px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.tb-connection-property {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 120px;
  width: 400px;
}
.tb-connection-property-label {
  display: inline-block;
  width: 120px;
  font-weight: bold;
}
.tb-connection-auth-error {
  color: #c41723;
}
.tb-auth-properties {
  padding-top: 35px;
}
